import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Icons from "../../assets/icons";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { GET_CATEGORIES } from "../../api/queries/categories.queries";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom/dist";
import { useCategory } from "../../context/CategoryContext";
import { CategoryProvider } from "../../context/CategoryContext";

export const useCategoryCardStyles = makeStyles((theme) => ({
  cardRoot: {
    width: "165px",
    maxWidth: "165px",
    transition: "box-shadow 0.3s",
    cursor: "pointer",
    borderRadius: "20px !important",
    boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.07) !important",
    color: theme.palette.primary.main,
    textAlign: "center",
    "&:active": {
     border: "4px solid #FE820E",
    },

  },
  disabledCard: {
    pointerEvents: "none",
    opacity: 0.5,
  
  },
  cardContent: {
    position: "relative",
    height: "100% !important",
    width: "100% !important",
    transition: "padding-bottom 0.3s ease"
  },
  spanStyle: {
    position: "relative",
    top: 10,
  },
  icon: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    margin: "0px auto 15px",
    alignItems: "center",
  },
}));

export const CategoryCard = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { data, classes, onClick, isSelected, isSticky  } = props;

  const theme = useTheme();
  const { t } = useTranslation("common");
  const {selectedCategory } = useCategory()
  const cardBackgroundColor = data?.bgColor;
  const selectedBorder = data.id === selectedCategory ? "4px solid #FE820E" : "none";


  return (
    <Box>

   
    <Card
    className={`${classes?.cardRoot}`}
      style={{ background: cardBackgroundColor, border: isSelected ? '4px solid #FE820E' : '4px solid transparent',}} 
      onClick={onClick}
      height={isSticky? "60px" : "165px"}
      transition={"height 0.3s ease-in-out"}
    >
      <CardContent className={classes?.cardContent} style={{paddingBottom: isSticky? "16px" : "24px"}}>
        <Box
          width={"100%"}
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          alignContent={"center"}
          justifyContent={"center"}
        >
      
          {!isSticky && (
            <Box className={classes?.icon}>
              {data?.image && data.image !== "undefined" ? (
                <img src={`${baseURL + data.image}`} alt={data?.title} style={{width:"69px", height:"54px", transition: "all 0.3s ease-in-out",}} />
              ) : (
                <Icons.NO_IMAGE />
              )}
            </Box>
          )}
     
          <Typography variant="h3" color={theme.palette.primary.main}>
            {data?.title}
          </Typography>
          {/* <span className={classes.spanStyle}>0 {t('categories.adds')}</span> */}
        </Box>
      </CardContent>
    </Card>
    </Box>
  );
};
const Categories = () => {
  const [isSticky, setIsSticky] = useState(false);
  const { selectedCategory, setSelectedCategory } = useCategory();
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useCategoryCardStyles(theme);
  const stickyclasses = useCategoryCardStyles({ isSticky })
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const initialCategoriesToShow = 12;
  const {
    loading: isLoadingCategories,
    error: errorLoadingCategories,
    data: categoriesList,
    refetch: refetchCategoriesList,
  } = useQuery(GET_CATEGORIES, {
    variables: {
      showOnScreen: 1,
    },
  });

  useEffect(() => {
    if (errorLoadingCategories) {
      console.log("error: ", errorLoadingCategories);
    }
  }, [errorLoadingCategories]);
  useEffect(() => {
    console.log("loading");
  }, [isLoadingCategories]);
  useEffect(() => {
    refetchCategoriesList();
  }, [i18n?.language]);

  
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId)
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <>
    
      <Stack
        width="100%"
        position= "relative"
      >
      </Stack>
      <Container sx={{ margin: "0 auto" }}>
      
        <Box
          style={{
            display: "flex",
            placeContent: "center",
            position: isSticky? "fixed" : "static",
            backgroundColor: isSticky? "#fff" : "unset" ,
            boxShadow: isSticky? "0px 2px 5px rgba(0,0,0,15%)" : "unset",
            top: isSticky? "0" : "unset",
            left: "0",
            right: "0",
            zIndex: "99999",
            transition:"top 0.3s ease-in-out"

          }}
     
        >
          {categoriesList &&
            categoriesList?.categories
              ?.slice(0, initialCategoriesToShow)
              .map((card, index) => (
                <Box
                  key={index}
                  style={{
                    boxSizing: "border-box",
                    padding: "10px"
                  }}
                 
                >
                  <CategoryCard classes={classes} data={card} isSticky={isSticky} onClick={() => handleCategoryClick(card.id)} isSelected={selectedCategory == card.id}/>
                </Box>
              ))}
      </Box>
   

      </Container>
    </>
  );
};
export default Categories;
