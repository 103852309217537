import React, { createContext, useState, useContext } from "react";

const CategoryContext = createContext();

export const useCategory = () => useContext(CategoryContext);

export const CategoryProvider = ({ categories, children }) => {
  const [selectedCategory, setSelectedCategory] = useState("2");

  const getSelectedCategoryServiceType = () => {
    const selectedCategoryData = categories?.find(
      (cat) => cat.id === String(selectedCategory)
    );
    return selectedCategoryData?.service_type;
  };

  return (
    <CategoryContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        getSelectedCategoryServiceType,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};
