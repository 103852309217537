import React, { useEffect } from 'react'

const AdVertical = () => {
    useEffect(() => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("AdSense error:", e);
        }
      }, []);
    
      return (
        <div style={{ textAlign: "center" }}>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-8783993549835852"
            data-ad-slot="5677432773"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      );
}

export default AdVertical