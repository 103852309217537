import gql from 'graphql-tag';
import api from '../../api/apiV2';
const FETCH_FORM = gql `
query FetchForm($categoryId: Int!, $steps: Int) {
  fetchForm(category_id: $categoryId, steps: $steps) {
    id
    category_id
    steps
    field_id
    field_name
    field_extras
    field_type
    field_validation
    field_size
    field_order
    field_request_type
  }
}
`;
const FETCH_AMENITIES = gql `
    query FetchAmenities {
        amenities {
            id
            title
        }
    }
`;
// const STORE_PROPERTY_MUTATION = gql `
//   mutation Store_property($args: JSON) {
//     store_property(args: $args) {
//       message
//       status
//     }
//   }
// `;


const STORE_PROPERTY_MUTATION = gql `
 mutation Store_property($args: JSON) {
  store_property(args: $args) {
    message
    status
    payment {
      currency_code
      country_code
      cart_description
      cart_id
      amount
    }
  }
}
`;



const UPLOAD_IMAGES = async (data) => {
    const token = localStorage.getItem('token');
    const response = await api.post(`/upload`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};


const DELETE_IMAGES = async (data) => {
    const response = await api.post(`/remove`, data);
    return response.data;
};
export { FETCH_FORM, FETCH_AMENITIES, STORE_PROPERTY_MUTATION, UPLOAD_IMAGES, DELETE_IMAGES };
