import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import ControlledButton from "../Button/button.component";
import CustomSelect from "../custom-select/custom-select.component";
import ModalPopup from "../modal-popup/modal-popup.component";
import { languages, citiesEn, citiesAr } from "../../data";
import { setLanguagePreference } from "../../helpers/global";
import Icons from "../../assets/icons";
import { BounceLoader } from 'react-spinners'
import {
  AppBar,
  Typography,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  Container,
  Button,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  GET_CATEGORIES,
  GET_CATEGORY_CHILD_BY_ID,
} from "../../api/queries/categories.queries";
import { useQuery } from "@apollo/client";
import Carousel, { consts } from "react-elastic-carousel";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import "./header.scss";
import { useMutation as useReactQueryMutation } from "react-query";
import { VERIFY_TOKEN } from "../../api/services/jwt.service";
import { logout } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { GET_PROPERTIES } from "../../api/queries/home.queries";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  setSelectedCountry,
  setSelectedState,
} from "../../store/slices/locationSlice";
import Loader from "../loader/Loader";

const apiURL = process.env.REACT_APP_REST_API_URL;
const useStyles = makeStyles((theme) => {
  return {
    carouselContainer: {
      backgroundColor: theme.palette.secondary.main,
      padding: "15px 145px",
      "& .rec-slider": {
        alignItems: "center",
      },
    },
    carouselItem: {
      backgroundColor: theme.palette.secondary.main,
      padding: "10px",
      margin: "auto !important",
    },
  };
});
const Header = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { i18n, t } = useTranslation("common");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const selectedCountry = useSelector((state) => state.location.selectedCountry);
  const selectedState = useSelector((state) => state.location.selectedState);

  console.log(selectedCountry, 'selected country')
  const theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCollapse, setIsCollapse] = React.useState(false);
  const handleCollapse = () => {
    setIsCollapse(!isCollapse);
  };
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedCity, setSelectedCity] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingSignOut, setLoadingSignOut] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLanguageSelectChange = (value) => {
    i18n.changeLanguage(value);
    setLanguagePreference(value);
  };
  const onCategorySelectChange = (value) => {
    setSelectedCategory(value);
  };
  const openDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const {
    loading: isLoadingCategories,
    error: errorLoadingCategories,
    data: categoriesList,
  } = useQuery(GET_CATEGORIES, {
    variables: {
      showOnScreen: 1,
    },
  });

  const {
    loading: isLoadingChildCategory,
    error: errorLoadingChildCategory,
    data: childCategory,
    refetch: refetchChildCategory,
  } = useQuery(GET_CATEGORY_CHILD_BY_ID, {
    variables: {
      afl: 1,
    },
    onCompleted: (data) => {
      console.log("Child category data fetched:", data);
    },
    onError: (error) => {
      console.error("Error fetching child category:", error);
    },
  });
  useEffect(() => {
    if (errorLoadingCategories) {
      console.error("Error loading categories:", errorLoadingCategories);
    }
  }, [errorLoadingCategories]);
  useEffect(() => {
    if (errorLoadingChildCategory) {
      console.error("Error loading child category:", errorLoadingChildCategory);
    }
  }, [errorLoadingChildCategory]);

  useEffect(() => {}, [isLoadingCategories, isLoadingChildCategory]);

  useEffect(() => {
    if (categoriesList) {
      categoriesList.categories.forEach((category) => {
        if (category.has_child) {
          refetchChildCategory({
            categoryId: Number(category.id),
            afl: 1,
          });
        }
      });
    }
  }, [categoriesList, refetchChildCategory]);

  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1024, itemsToShow: 6 },
    { width: 1200, itemsToShow: 7 },
  ];

  const handleCarouselChange = (currentIndex) => {
    setActiveIndex(currentIndex);
    console.log("Active carousel index:", currentIndex);
  };
  const carouselArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <Icons.ARROW style={{ transform: "rotate(180deg)" }} />
      ) : (
        <Icons.ARROW />
      );
    return (
      <Button
        onClick={onClick}
        disabled={isEdge}
        style={{ cursor: isEdge ? "not-allowed" : "pointer" }}
      >
        {pointer}
      </Button>
    );
  };
  const onPostAdClick = () => {
    if (isLoggedIn) {
      navigate(ROUTES.SELECT_COUNTRY);
    } else {
      navigate(ROUTES.LOGIN_METHODS);
    }
  };
  const [openCityModal, setOpenCityModal] = useState(false);

  useEffect(() => {
    if (!selectedCountry || !selectedState) {
      setOpenCityModal(true);
    }
  }, [selectedCountry, selectedState]);

  const closeCities = () => {
    setOpenCityModal(false);
  };
  const openCities = () => {
    setOpenCityModal(true);
  };
  const handleCityClick = (cityId) => {
    setSelectedCity(cityId);
  };
  const getCitiesByLanguage = (lang) => {
    switch (lang) {
      case "en":
        return citiesEn;
      case "ar":
        return citiesAr;
      default:
        return citiesEn;
    }
  };
  const selectedCities = getCitiesByLanguage(i18n?.language);
  const citiesList = selectedCities?.map((city, index) => (
    <Button
      key={index}
      className={`city ${selectedCity === city?.value ? "selected" : ""}`}
      onClick={() => handleCityClick(city?.value)}
    >
      {city?.name}
    </Button>
  ));

  const handleCategoryClick = (categoryId) => {
    navigate(`${ROUTES.PROPERTY_FOR_RENT.replace(":categoryId", categoryId)}`);
  };

  const handleLoginClick = () => {
    navigate(ROUTES.LOGIN);
  };

  const verifyToken = useReactQueryMutation(VERIFY_TOKEN, {
    async onSuccess(token) {
      if (!token["verified"]) {
        setTimeout(() => dispatch(logout()), 2000);
      }
    },
    onError(error) {
      console.log(error);
    },
  });
  useEffect(() => {
    if (localStorage.getItem("token")) {
      verifyToken.mutate(localStorage.getItem("token"));
    }
  }, [dispatch]);

  const handleLogout = async () => {
    setLoadingSignOut(true);
    await new Promise((resolve) => {
      setTimeout(() => {
        dispatch(logout());
        resolve();
      }, 2000);
    });
    navigate(ROUTES.HOME);
    setLoadingSignOut(false); 
    handleClose();
  };

  let userId = null;
  let userData = localStorage.getItem("userData");
  let user = JSON.parse(userData);

  if (user) {
    userId = parseInt(user.id);
  }

  const { loading, error, data } = useQuery(GET_PROPERTIES, {
    variables: {
      page: null,
      userId: userId,
    },
  });

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  const totalAds = parseInt(data?.properties?.total);

  const renderOption = (option) => (
    <li key={option.code} onClick={() => onLanguageSelectChange(option.code)}>
      {option.name}
    </li>
  );

  // fetching countries:

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${apiURL}/countries`, {
          headers: {
            "X-App-Language": i18n?.language,
          },
        });
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchCountries();
  }, []);

  const handleChange = async (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountryObj = countries.find(
      (country) => country.name === selectedCountryName
    );
  
    if (selectedCountryObj) {
      // Dispatch country name and ID
      dispatch(
        setSelectedCountry({
          name: selectedCountryObj.name,
          id: selectedCountryObj.id,
        })
      );
  
      setLoadingStates(true);
      try {
        const response = await axios.get(
          `${apiURL}/states-by-country?country_id=${selectedCountryObj.id}`,
          {
            headers: {
              "X-App-Language": i18n?.language,
            },
          }
        );
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoadingStates(false);
      }
    } else {
      setStates([]);
    }
  };

  const handleStateSelection = (state) => {
    if (state.name === "ALL") {
      dispatch(setSelectedState(null));  
      localStorage.removeItem("selectedState");
      localStorage.removeItem("state_id");
    } else {
      dispatch(
        setSelectedState({
          name: state.name,
          id: state.id,
        })
      );
      localStorage.setItem("selectedState", state.name);
      localStorage.setItem("state_id", state.id);
    }
    setOpenCityModal(false);
  };
  
useEffect(() => {
  const storedState = localStorage.getItem("selectedState");
  const storedCountry = localStorage.getItem("selectedCountry");
  const storedStateId = localStorage.getItem("state_id");
  const storedCountryId = localStorage.getItem("country_id");
  if (storedCountry && storedCountryId) {
    dispatch(setSelectedCountry({ name: storedCountry, id: storedCountryId }));
    const fetchStatesForStoredCountry = async () => {
      setLoadingStates(true);
      try {
        const response = await axios.get(
          `${apiURL}/states-by-country?country_id=${storedCountryId}`,
          { headers: { "X-App-language": i18n.language } }
        );
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoadingStates(false);
      }
    };
    fetchStatesForStoredCountry();
  }
  if (storedState && storedStateId) {
    dispatch(setSelectedState({ name: storedState, id: storedStateId }));
  }
}, [dispatch, apiURL, i18n.language]);



  return (
    <>
      <Container sx={{paddingLeft: isMobile? "0px !important" : "24px !important", paddingRight: isMobile? "0px !important" : "24px !important"}}>
        <AppBar
          position="static"
          sx={{
            bgcolor: theme.palette.secondary.main,
            zIndex: 1201,
            position: "relative",
            background: isMenuOpened ? "#F5F5F5" : theme.palette.secondary.main,
          }}
          style={{ boxShadow: "none", padding: isMobile ? "12px" : "35px 0px" }}
          className={"ltr-layout"}
        >
          <Grid
            container
            spacing={3}
            className={"header-grid"}
            sx={{ flexWrap: "nowrap" }}
          >
            <Grid paddingLeft={isMobile ? "unset !important" : "24px"} item xs>
              <Link to={ROUTES.HOME}>
                <Icons.NEW_LOGO
                  style={{
                    width: isMobile ? "120px" : "unset",
                    
                  }}
                />
              </Link>
            </Grid>
            {!isMobile && (
              <Grid
                item
                xs={6}
                display={"flex"}
                gap={"24px"}
                alignItems={"center"}
                sx={{ placeContent: "center" }}
              >
                <Link style={{ textDecoration: "none" }} to={ROUTES.ABOUT_US}>
                  <Typography
                    variant="body2"
                    marginX={1}
                    sx={{ cursor: "pointer" }}
                    color={theme.palette.primary.main}
                  >
                    {t("header.about")}
                  </Typography>
                </Link>

                <Link style={{ textDecoration: "none" }} to={ROUTES.CONTACT_US}>
                  <Typography
                    variant="body2"
                    marginX={1}
                    sx={{ cursor: "pointer", textDecoration: "none" }}
                    color={theme.palette.primary.main}
                  >
                    {t("header.contact")}
                  </Typography>
                </Link>
              </Grid>
            )}
            <Grid
              paddingLeft={isMobile ? "unset !important" : "24px"}
              item
              xs
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"11px"}
            >
              <Box
                display="flex"
                marginLeft={!isMobile ? "auto" : ""}
                height="50px"
                gap="20px"
                paddingRight={isMobile ? "0px" : "0px"}
                flexWrap="nowrap"
                alignItems="center"
              >
                {!isMobile && (
                  <Button
                    className="city-btn"
                    style={{
                      width: "auto",
                      padding: "0px 12px",
                      minWidth: "unset",
                      maxWidth: "100%",
                      whiteSpace: "nowrap",
                    }}
                    onClick={openCities}
                  >
                    {selectedCountry && selectedState
                      ? `${selectedState}, ${selectedCountry}`
                      : t("header.city")}
                    <ExpandMoreOutlinedIcon sx={{ fontSize: "16px" }} />
                  </Button>
                )}
                <ControlledButton
                  value={t("header.post_ad")}
                  customStyle={{
                    backgroundColor: theme.palette.primary.orange,
                    color: theme.palette.secondary.main,
                  }}
                  className="post-ad-btn"
                  onClick={onPostAdClick}
                />
              </Box>

              {isMobile ? (
                <IconButton onClick={openDrawer}>
                  {isDrawerOpen ? <Icons.MENU_CLOSE /> : <Icons.MENU_OPEN />}
                </IconButton>
              ) : (
                <Box display={"flex"} alignItems={"center"} gap={"15px"}>
                  {isLoggedIn ? (
                    <>
                      <Link to={ROUTES.CHAT}>
                        <Icons.CHAT style={{ cursor: "pointer" }} />
                      </Link>
                      <Link to={ROUTES.FAVORITES}>
                        <Icons.HEART style={{ cursor: "pointer" }} />
                      </Link>
                      {/* <Link>
                        <Icons.BELL style={{ cursor: "pointer" }} />
                      </Link> */}
                      <Box>
                        <Icons.USER
                          style={{ cursor: "pointer" }}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          MenuProps={{
                            getContentAnchorEl: null,
                          }}
                          sx={{
                            "& .MuiMenu-paper": {
                              width: "280px",
                              marginTop: "30px",
                              borderRadius: "20px",
                              position: "relative",
                              overflowX: "unset",
                              overflowY: "unset",
                              marginLeft: "50px",

                              "&::before": {
                                content: '""',
                                position: "absolute",
                                marginRight: "-0.71em",
                                right: "16%",
                                top: "-8%",
                                width: "30px",
                                height: "30px",
                                backgroundColor: "#fff",
                                boxShadow: "0px 5px 20px rgba(0, 0, 0, 16%)",
                                transform:
                                  "translate(-50%, 50%) rotate(-45deg)",
                                clipPath:
                                  "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
                              },
                            },
                          }}
                        >
                          <MenuItem
                            className="menu-item"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n?.language === "en" ? "row" : "row-reverse",
                              gap: "30px",
                              borderBottom: "1px solid #d1d1d1",
                              paddingBottom: "15px",
                            }}
                            onClick={handleClose}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px !important",
                                fontWeight: "600 !important",
                              }}
                            >
                              {t("header.my-profile")}
                            </Typography>{" "}
                            <Button
                              sx={{
                                border: "1px solid #d1d1d1",
                                borderRadius: "8px",
                                color: "#000",
                                display: "flex",
                                fontSize: "14px !important",
                                gap: "8px",
                              }}
                            >
                              {" "}
                              <Icons.TICK /> {t("header.get-verified")}
                            </Button>
                          </MenuItem>

                          <Link
                            style={{ textDecoration: "none" }}
                            to={`${ROUTES.PROFILE}?tab=1`}
                          >
                            {" "}
                            <MenuItem
                              style={{
                                display: "flex",
                                flexDirection:
                                  i18n?.language === "en"
                                    ? "row"
                                    : "row-reverse",
                                gap: "15px",
                                paddingBottom: "15px",
                                paddingTop: "15px",
                                color: "#000",
                                fontSize: "14px !important",
                              }}
                              onClick={handleClose}
                            >
                              {" "}
                              <Icons.PROFILE
                                style={{ width: "24px", height: "24px" }}
                              />{" "}
                              <Typography sx={{ fontSize: "14px !important" }}>
                                {" "}
                                {t("header.my-profile")}
                              </Typography>
                            </MenuItem>
                          </Link>

                          {/* <MenuItem
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n?.language === "en" ? "row" : "row-reverse",
                            gap: "15px",
                            paddingBottom: "15px",
                            color: "#000",
                          }}
                          onClick={handleClose}
                        >
                          <Icons.PROFILE
                            style={{ width: "24px", height: "24px" }}
                          />{" "}
                          <Typography sx={{ fontSize: "14px !important" }}>
                            {t("header.public-profile")}
                          </Typography>
                        </MenuItem> */}

                          <Link
                            style={{ textDecoration: "none" }}
                            to={`${ROUTES.PROFILE}?tab=0`}
                          >
                            <MenuItem
                              style={{
                                display: "flex",
                                flexDirection:
                                  i18n?.language === "en"
                                    ? "row"
                                    : "row-reverse",
                                gap: "15px",
                                paddingBottom: "15px",
                                color: "#000",
                              }}
                              onClick={handleClose}
                            >
                              <Icons.ADICON
                                style={{ width: "24px", height: "24px" }}
                              />{" "}
                              <Typography sx={{ fontSize: "14px !important" }}>
                                {t("header.my-ads")}
                              </Typography>
                              <Button
                                sx={{
                                  border: "1px solid #d1d1d1",
                                  borderRadius: "8px",
                                  color: "#000",
                                  height: "40px",
                                  fontSize: "14px !important",
                                }}
                              >
                                {t("header.total")} {totalAds}
                              </Button>
                            </MenuItem>
                          </Link>

                          <MenuItem
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n?.language === "en" ? "row" : "row-reverse",
                              gap: "15px",
                              paddingBottom: "15px",
                              color: "#000",
                            }}
                            onClick={handleClose}
                          >
                            <Icons.DSEARCH
                              style={{ width: "24px", height: "24px" }}
                            />{" "}
                            <Typography sx={{ fontSize: "14px !important" }}>
                              {t("header.my-searches")}
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n?.language === "en" ? "row" : "row-reverse",
                              gap: "15px",
                              paddingBottom: "15px",
                              color: "#000",
                            }}
                            onClick={handleClose}
                          >
                            <Icons.DBOOKMARK
                              style={{ width: "24px", height: "24px" }}
                            />
                            <Typography sx={{ fontSize: "14px !important" }}>
                              {t("header.my-bookmarks")}
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n?.language === "en" ? "row" : "row-reverse",
                              gap: "15px",
                              paddingBottom: "15px",
                              color: "#000",
                            }}
                            onClick={handleClose}
                          >
                            <Icons.DSETTINGS
                              style={{ width: "24px", height: "24px" }}
                            />{" "}
                            <Typography sx={{ fontSize: "14px !important" }}>
                              {t("header.account-settings")}
                            </Typography>
                          </MenuItem>

                          <MenuItem
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n?.language === "en" ? "row" : "row-reverse",
                              gap: "15px",
                              paddingBottom: "15px",
                              color: "#000",
                            }}
                            onClick={handleLogout}
                          >
                            <Icons.LOGOUT
                              style={{ width: "24px", height: "24px" }}
                            />
                            <Typography sx={{ fontSize: "14px !important" }}>
                              {t("header.signout")}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </>
                  ) : (
                    <Button
                      sx={{
                        cursor: "pointer",
                        textTransform: "capitalize",
                        width: "max-content",
                        color: theme.palette.primary.main,
                        border: "1px solid #000",
                        textDecoration: "unset",
                        fontSize: "16px",
                        width: "100px",
                        height: "47px",
                        borderRadius: "8px",
                        fontWeight: "600",
                      }}
                      onClick={handleLoginClick}
                    >
                      {t("header.login_sign_up")}
                    </Button>
                  )}
                  <CustomSelect
                    className="languages-select"
                    sx={{
                      color: theme.palette.primary.main,

                      "& .MuiSvgIcon-root": {
                        width: "0em",
                      },
                    }}
                    options={languages}
                    selected={t(`header.lang.${i18n.language}`)}
                    onSelectChange={onLanguageSelectChange}
                    getOptionLabel={(option) =>
                      t(`header.lang.${option.value}`) || ""
                    }
                    placeholder={t(`header.lang.${i18n.language}`)}
                    searchable={false}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </AppBar>
        <Drawer
          className="mobile-nav"
          anchor="right"
          open={isDrawerOpen}
          PaperProps={{
            sx: {
              marginTop: "85px !important",
              width: "100%",
              position: "relative",
              zIndex: "99999",
            },
          }}
        >
          <List sx={{ width: "100% !important" }}>
            <ListItem className="head-item">
              <Link
                to={ROUTES.ABOUT_US}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: i18n.language === "en" ? "row" : "row-reverse",
                }}
              >
                <Typography
                  marginX={0}
                  color={theme.palette.primary.main}
                  fontWeight={"bold"}
                  sx={{ fontSize: "16px !important" }}
                >
                  {t("header.about")}
                </Typography>
                {i18n.language === "en" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </Link>
            </ListItem>

            <ListItem className="head-item">
              {" "}
              <Link
                to={ROUTES.CONTACT_US}
                style={{
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width:"100%",
                  flexDirection: i18n.language === "en" ? "row" : "row-reverse",
                }}
              >
                <Typography
                  marginX={0}
                  color={theme.palette.primary.main}
                  fontWeight={"bold"}
                  sx={{ fontSize: "16px !important", paddingBottom: "15px" }}
                >
                  {t("header.contact")}
                </Typography>
                {i18n.language === "en" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </Link>
            </ListItem>

            <ListItem
              className="head-item"
              sx={{
                borderTop: "1px solid #cccccc",
                borderBottom: "1px solid #cccccc",
              }}
            >
              <Link
                style={{
                  textDecoration: "none !important",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width:"100%",
                  flexDirection: i18n.language === "en" ? "row" : "row-reverse",
                }}
                className="city-btn"
                onClick={openCities}
              >
                <Typography
                  marginX={0}
                  color={theme.palette.primary.main}
                  fontWeight={"bold"}
                  sx={{ fontSize: "16px !important" }}
                >
                 {selectedCountry && selectedState
                      ? `${selectedState}, ${selectedCountry}`
                      : t("header.city")}
                </Typography>
                {i18n.language === "en" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </Link>
            </ListItem>
            {user ? (
              <Box
                display={"flex"}
                gap={"20px"}
                sx={{
                  placeContent: "center",
                  flexDirection: "column",
                  paddingTop: "28px",
                  paddingBottom: "15px",
                  paddingLeft: "16px",
                  paddingRight: i18n.language === "en" ? "0px" : "16px",
                }}
              >
                <Link
                  to={ROUTES.CHAT}
                  style={{
                    display: "flex",
                    flexDirection:
                      i18n.language === "en" ? "row" : "row-reverse",
                    color: "#000",
                    textDecoration: "none",
                    gap: "14px",
                  }}
                >
                  <Icons.CHAT style={{ cursor: "pointer" }} />{" "}
                  <Typography sx={{ fontSize: "16px !important" }}>
                    {t("header.chat")}
                  </Typography>
                </Link>
                <Link
                  style={{
                    display: "flex",
                    flexDirection:
                      i18n.language === "en" ? "row" : "row-reverse",
                    color: "#000",
                    textDecoration: "none",
                    gap: "14px",
                  }}
                  to={ROUTES.FAVORITES}
                >
                  {" "}
                  <Icons.HEART style={{ cursor: "pointer" }} />
                  <Typography sx={{ fontSize: "16px !important" }}>
                    {t("header.fav")}
                  </Typography>
                </Link>

                <Link
                  style={{
                    display: "flex",
                    flexDirection:
                      i18n.language === "en" ? "row" : "row-reverse",
                    color: "#000",
                    textDecoration: "none",
                    gap: "14px",
                  }}
                >
                  {" "}
                  {/* <Icons.BELL style={{ cursor: "pointer" }} /> */}
                  {/* <Typography sx={{ fontSize: "16px !important" }}>
                    {t("header.notifications")}
                  </Typography> */}
                </Link>

                <Box display={"flex"} position={"relative"}>
                  <List
                    sx={{
                      width: "100%",
                      paddingTop: "0px !important",
                    }}
                  >
                    <ListItem
                      onClick={handleCollapse}
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: "0px !important",
                        paddingLeft: "0px",
                        paddingRight: i18n.language === "en" ? "16px" : "0px",
                        flexDirection:
                          i18n.language === "en" ? "row" : "row-reverse",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection:
                            i18n.language === "en" ? "row" : "row-reverse",
                          color: "#000",

                          textDecoration: "none",
                          gap: "14px",
                        }}
                      >
                        <Icons.USER
                          style={{ cursor: "pointer" }}
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        />
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {t("header.user")}
                        </Typography>
                      </Box>
                      {isCollapse ? (
                        <ExpandLessIcon />
                      ) : i18n.language === "en" ? (
                        <ChevronRightIcon />
                      ) : (
                        <ChevronLeftIcon />
                      )}
                    </ListItem>

                    <Collapse
                      in={isCollapse}
                      timeout="auto"
                      unmountOnExit
                      sx={{
                        paddingLeft: "38px",
                        paddingRight: i18n.language === "en" ? "0px" : "0px",
                      }}
                    >
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`${ROUTES.PROFILE}?tab=1`}
                      >
                        <MenuItem
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n?.language === "en" ? "row" : "row-reverse",
                            gap: "18px",
                            paddingBottom: "15px",
                            paddingTop: "15px",
                            color: "#000",
                          }}
                        >
                          {" "}
                          <Icons.PROFILE />{" "}
                          <Typography sx={{ fontSize: "16px !important" }}>
                            {" "}
                            {t("header.my-profile")}
                          </Typography>
                        </MenuItem>
                      </Link>

                      {/* <MenuItem
                      style={{
                        display: "flex",
                        flexDirection:
                          i18n?.language === "en" ? "row" : "row-reverse",
                        gap: "18px",
                        paddingBottom: "15px",
                        color: "#000",
                      }}
                    >
                      <Icons.PROFILE />{" "}
                      <Typography sx={{ fontSize: "16px !important" }}>
                        {t("header.public-profile")}
                      </Typography>
                    </MenuItem> */}

                      <Link
                        style={{ textDecoration: "none" }}
                        to={`${ROUTES.PROFILE}?tab=0`}
                      >
                        <MenuItem
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n?.language === "en" ? "row" : "row-reverse",
                            gap: "18px",
                            paddingBottom: "15px",
                            color: "#000",
                            fontSize: "16px !important",
                          }}
                        >
                          <Icons.ADICON />{" "}
                          <Typography sx={{ fontSize: "16px !important" }}>
                            {t("header.my-ads")}
                          </Typography>
                          <Button
                            sx={{
                              border: "1px solid #d1d1d1",
                              borderRadius: "8px",
                              color: "#000",
                              fontSize: "14px",
                              height: "40px",
                            }}
                          >
                            {t("header.total")} {totalAds}
                          </Button>
                        </MenuItem>
                      </Link>

                      <MenuItem
                        style={{
                          display: "flex",
                          flexDirection:
                            i18n?.language === "en" ? "row" : "row-reverse",
                          gap: "18px",
                          paddingBottom: "15px",
                          color: "#000",
                        }}
                      >
                        <Icons.DSEARCH />{" "}
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {t("header.my-searches")}
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        style={{
                          display: "flex",
                          flexDirection:
                            i18n?.language === "en" ? "row" : "row-reverse",
                          gap: "18px",
                          paddingBottom: "15px",
                          color: "#000",
                        }}
                      >
                        <Icons.DBOOKMARK />{" "}
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {t("header.my-bookmarks")}
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        style={{
                          display: "flex",
                          flexDirection:
                            i18n?.language === "en" ? "row" : "row-reverse",
                          gap: "18px",
                          paddingBottom: "15px",
                          color: "#000",
                        }}
                      >
                        <Icons.DSETTINGS />{" "}
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {t("header.account-settings")}
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        style={{
                          display: "flex",
                          flexDirection:
                            i18n?.language === "en" ? "row" : "row-reverse",
                          gap: "18px",
                          paddingBottom: "15px",
                          color: "#000",
                        }}
                        onClick={handleLogout}
                      >
                        <Icons.LOGOUT />{" "}
                        <Typography sx={{ fontSize: "16px !important" }}>
                          {t("header.signout")}
                        </Typography>
                      </MenuItem>
                    </Collapse>
                  </List>
                </Box>
              </Box>
            ) : (
              <ListItem
                sx={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                  color: theme.palette.primary.main,
                  textDecoration: "unset",
                  paddingLeft: i18n.language === "en" ? "25px" : "16px",
                  paddingRight: i18n.language === "en" ? "16px" : "25px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: i18n.language === "en" ? "row" : "row-reverse",
                  alignItems: "center",
                  margin: "16px 0px",
                }}
                onClick={handleLoginClick}
              >
                <Typography sx={{ fontSize: "16px", fontWeight: "600 " }}>
                  {" "}
                  {t("header.login_sign_up")}
                </Typography>
                {i18n.language === "en" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </ListItem>
            )}
            <ListItem
              className="language-list"
              style={{
                color: "#000",
                flexDirection: "column",
                alignItems: i18n.language === "en" ? "start" : "end",
                borderTop: "1px solid #ccccc",
                paddingTop: "28px",
              }}
            >
              {languages.map((language) => (
                <Typography
                  marginX={0}
                  key={language.value}
                  onClick={() => onLanguageSelectChange(language.value)}
                  style={{
                    cursor: "pointer",
                    color: i18n.language === language.value ? "#000" : "#000",
                    textTransform: "capitalize",
                  }}
                  sx={{
                    fontSize: "16px !important",
                    marginBottom: "15px",
                  }}
                >
                  {language.value}
                </Typography>
              ))}
            </ListItem>
          </List>
        </Drawer>

        <Drawer
          anchor="top"
          open={isMenuOpened}
          PaperProps={{
            sx: {
              marginTop: "130px!important",
              borderBottomLeftRadius: "20px",
              borderBottomRightRadius: "20px",
            },
          }}
          onBackdropClick={openMenu}
        >
          <Carousel
            breakPoints={breakPoints}
            className={classes?.carouselContainer}
            isRTL={false}
            pagination={false}
            onChange={handleCarouselChange}
            renderArrow={carouselArrow}
          >
            {categoriesList &&
              categoriesList?.categories?.map((card, index) => {
                return (
                  <Box key={index} className={classes?.carouselItem}>
                    <Box sx={{ textAlign: "center" }}>
                      {card?.image.includes("undefined") ? (
                        <Icons.NO_IMAGE />
                      ) : (
                        <img
                          src={`${baseURL + card?.image}`}
                          alt={card?.title}
                        />
                      )}
                    </Box>
                    <Typography
                      color={activeIndex?.item?.children?.map((activeItem) =>
                        activeItem?.props?.children === card?.title
                          ? "#B9B9B9"
                          : theme.palette.primary.main
                      )}
                    >
                      {card?.title}
                    </Typography>
                  </Box>
                );
              })}
          </Carousel>

          <Container
            sx={{
              borderTop: "1px solid #D9D9D9;",
              direction: i18n.language.toLowerCase() === "en" ? "ltr" : "rtl",
              padding: "40px 160px",
              maxWidth: "90%",
            }}
          >
            {categoriesList &&
              categoriesList?.categories?.map((category) => {
                return activeIndex?.item?.children.map((activeItem) => {
                  if (
                    activeItem?.props?.children === category?.title &&
                    category?.has_child
                  )
                    return childCategory?.categories?.map(
                      (childItem, index) => (
                        <ListItem key={index}>{childItem?.title}</ListItem>
                      )
                    );
                });
              })}
          </Container>
        </Drawer>

        <ModalPopup
          closePopup={closeCities}
          open={openCityModal}
          title={t("header.choose-city")}
          children={
            <div className="cities-container">
              <Box style={{ width: "100%" }}>
                <FormControl variant="outlined" size="small" fullWidth>
                  {/* <InputLabel id="country-select" htmlFor="select">
                  {t('country.select-label')}
                </InputLabel>  */}
                  <Select
                    native
                    labelId="country-select"
                    id="select"
                    value={selectedCountry}
                    onChange={handleChange}
                    sx={{
                      color: "#000 !important",
                      "&:focus": {
                        border: "none",
                      },
                    }}
                  >
                    {countries
                      .filter((option) => option.active_for_listing === 1)
                      .map((option) => (
                        <option
                          style={{ color: "#000" }}
                          key={option.id}
                          value={option.name}
                        >
                          {option.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <div className="city-btns">
  {loadingStates ? (
    <BounceLoader color="#FE820E" size={30} />
  ) : (
    
<>
  <Button
    className={`state-btn ${!selectedState ? 'selected' : ''}`}
    onClick={() => handleStateSelection({ name: "All" })}
    sx={{
      border: "1px solid #EFEFEF",
      backgroundColor: !selectedState ? "#FE820E" : "transparent", 
      color: !selectedState ? "#fff" : "#000", 
    }}
  >
    {t("header.All")}
  </Button>
  {states.map((state) => (
  <Button
    key={state.id}
    className={`state-btn ${state.id === selectedState?.id ? 'selected' : ''}`}
    onClick={() => handleStateSelection(state)}
    sx={{
      border: "1px solid #EFEFEF",
      background: state.id === selectedState?.id ? "#FE820E" : "transparent", 
      color: state.id === selectedState?.id ? "#fff" : "#000",
      '&:hover': {
        backgroundColor: state.id === selectedState?.id ? "#FE820E" : "rgba(0, 0, 0, 0.08)",
      },
    }}
  >
    {state.name}
  </Button>
))}
</>

  )}
</div>
            </div>
          }
          styles={{
            width: "606px",
            borderRadius: "30px",
            background: "#FFF",
            boxShadow: "0px 4px 54px 0px rgba(0, 0, 0, 0.25)",
            position: "relative",
            zIndex: 9999999,
          }}
        />


       
      </Container>

      {
        loadingSignOut && (
          <div>
          <Loader/>
          </div>
        )
      }
    </>
  );
};
export default Header;
