import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import IconWithBadge from "../../components/icon-with-badge/icon-with-badge.component";
import * as ROUTES from "../../constants/routes";
import Icons from "../../assets/icons";
import Images from "../../assets/images";
import { makeStyles } from "@mui/styles";
import PanoramaImageViewer from "../../components/panorama-image-viewer/panorama-image-viewer.component";
import ResponsiveCarousel from "../../components/responsive-carousel/responsive-carousel.component";
import ControlledMap from "../../components/geo-map/map.component";
import { formatNumberWithCommas } from "../../helpers/global";
import { Container, fontSize } from "@mui/system";
import AdsImg from "../../assets/images/ads-Image.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { VERIFY_TOKEN } from "../../api/services/jwt.service";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GET_MOTOR_BY_ID } from "../../api/queries/motors.queries";
import ReactImageGallery from "react-image-gallery";
import { FaXmark } from "react-icons/fa6";
import { FaAngleLeft } from "react-icons/fa6";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { FaAngleRight } from "react-icons/fa6";
import { decodeId, encodeId } from "../../helpers/idEncryption";
import { generatePath } from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import 'react-image-gallery/styles/css/image-gallery.css';
import './motors-search/search.scss'
import Loader from "../../components/loader/Loader";
import GoogleAds from "../../components/google-ads/GoogleAds";
import AdVertical from "../../components/google-ads/AdVertical";
const apiURL = process.env.REACT_APP_REST_API_URL;
const appURL = process.env.REACT_APP_BASE_URL;
const useStyles = makeStyles(() => ({
  icon: {
    padding: 9,
    margin: 5,
    borderRadius: 9,
    width: 44,
    height: 44,
    border: "1px solid #D9D9D9",
    cursor: "pointer",
  },
  sliderIcons: {
    padding: 10,
    margin: 5,
    borderRadius: 9,
    width: 48,
    height: 48,
    backgroundColor: "#00000080",
    cursor: "pointer",
  },
  selectedIcon: {
    backgroundColor: "#000",
  },
}));

const MotorDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const decryptedId = decodeId(id)
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const dispatch = useDispatch();

  const { id: paramId} = useParams()

  useEffect(() => {

    const unhashedId = decodeId(paramId);
      if(!unhashedId){
        const hashedId = encodeId(paramId)
        if(hashedId){
            navigate(generatePath(`${ROUTES.MOTOR_DETAILS}`, {id: hashedId}))
        }

        else{
          console.log("invalid id")
        }
      }
      else{
        console.log("unbale to decode", unhashedId)
      }

  }, [paramId, navigate])


  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [initialImageIndex, setInitialImageIndex] = useState(0);


   // Prepare images for the gallery
 
   const openGallery = (index) => {
  
    setInitialImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const [textMessage, setTextMessage] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    loading: isMotorLoading,
    error: errorLoadingMotors,
    data,
  } = useQuery(GET_MOTOR_BY_ID, {
    variables: {
      motorId: Number(decryptedId),
    },
  });



  useEffect(() => {
    if (errorLoadingMotors) {
      console.error("Error motors:", errorLoadingMotors);
    }
  }, [errorLoadingMotors]);
  useEffect(() => {
    console.log("loading");
  }, [isMotorLoading]);


  const motor = data?.["motor"];


  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const verifyTokenExtractUser = async () => {
      try {
        const token = localStorage.getItem("token");

        if (token) {
          const user = await VERIFY_TOKEN(token);
          setCurrentUser(user);
        }
      } catch (error) {
        console.log("error verifying the token");
      }
    };

    verifyTokenExtractUser();
  }, []);

  const storedUser = localStorage.getItem("userData");
  const parsedUserData = storedUser ? JSON.parse(storedUser) : null;
  const userId = parsedUserData ? parsedUserData.id : null;



  const handleSendMessage = async () => {
    if (textMessage) {
      try {
        const {
          id,
          category_id,
          user_id: { id: receiverid },
        } = motor;
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("item_id", id);
        formData.append("category_id", category_id);
        formData.append("receiver_id", receiverid);
        formData.append("sender_id", userId);

        if (textMessage) {
          formData.append("message", textMessage);
          formData.append("type", "text");
        }

        const response = await axios.post(
          `${apiURL}/pusher/send-message`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setTextMessage("");
          const chatId = response.data.chat_id;
          navigate(`${ROUTES.CHAT_BY_ID.replace(":chat_id", chatId)}`);
        } else {
          console.error("Failed to send message:", response.statusText);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const isOwner = motor?.user_id?.id === currentUser?.id;

  const memberStyles = {
    borderRadius: "100px !important",
    background: "#EFEFEF",
    padding: "9px 12px !important",
    display: "flex",
    gap: "5px!important",
    margin: "12px 20px !important",
    position: "absolute",
    right: 0,
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const userBadge = {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  };
  const contactBadge = {
    borderRadius: "8px",
    border: "1px solid #EFEFEF",
    padding: "12px 18px",
    margin: "8px 0px!important",
    display: "flex",
    alignItems: "center",
    fontSize: "16px !important",
    color: "#000!important ",
  };

  const contactBadgeFeatures = {
    borderRadius: "8px",
    border: "1px solid #EFEFEF",
    padding: "5px 10px",
    width: "180px",
    margin: "8px 0px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "14px !important",
    color: "#000!important ",
  };
  const [selectedIcon, setSelectedIcon] = useState("responsive-carousel");
  const handleIconClick = (iconType) => {
    setSelectedIcon(iconType);
  };
  const showPanorama = selectedIcon === "panorama-viewer";
  const showVideoBox = selectedIcon === "video";
  const showCarousel = selectedIcon === "responsive-carousel";
  const showMap = selectedIcon === "geo-map";

  const youtubeUrl = motor?.socialmedia
    ?.map((item) => {
      if (item.type === "youtube_url") {
        return item.value;
      }
      return null;
    })
    .find((value) => value !== null);
  const isValidYouTubeUrl = (url) => {
    const youtubeUrlRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    return youtubeUrlRegex.test(url);
  };
  const MediaNotAvailable = ({ child, text }) => (
    <Box display={"grid"} height={"100%"}>
      <Box alignSelf={"center"} justifySelf={"center"}>
        {child}
        <Typography color={theme.palette.primary.main}>{text}</Typography>
      </Box>
    </Box>
  );
  const handleBackClick = () => {
    navigate(ROUTES.HOME)
  }

  const baseURL = process.env.REACT_APP_BASE_URL;

  const galleryImages = motor?.images.map((img) => ({
    original: baseURL + img.image,
    thumbnail: baseURL + img.image,
  }));

  return (
    <>
      <Helmet>
        <title>{t("motors.meta-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Container sx={{ minHeight: "100vh" }} className="container">
        <Grid
          maxWidth={1320}
          container
          direction={"row"}
          style={
            isMobile
              ? {
                  padding: 0,
                  display: "grid",
                  margin: "20px 0",
                  gap: "5px",
                }
              : {
                  padding: "20px 0px",
                  display: "flex",
                  alignItems: "center",
                }
          }
        >
          <Grid item xs={8}>
          <Box sx={{
              display:"flex",
              alignItems:"center",
              gap:"8px",
              fontSize:"18px !important",
              color:"#000",
              cursor: "pointer",
              fontWeight: "400",
              width: "70px"
             }} onClick={handleBackClick}>{i18n?.language === "en"? <FaAngleLeft size={18} /> : <FaAngleRight  size={18} />} {t('common.back')} </Box>
          </Grid>
          <Grid item xs />
          <Grid item xs textAlign={"end"}>
            <Icons.HEART className={classes.icon} />
            <Icons.SHARE className={classes.icon} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={
            isMobile
              ? {
                  display: "block",
                  width: "100%",
                  margin: "0px",
                  padding: 0,
                }
              : {
                  flexDirection: "row",
                  display: "flex",
                  height: "100%",
                  margin: "unset",
                  width: "unset",
                }
          }
        >
          <Grid
            item
            xs={12}
            style={
              isMobile
                ? {
                    width: "100%",
                    maxWidth: "100%",
                    padding: "unset",
                  }
                : {
                    height: "max-content",
                    padding:
                      i18n?.language === "en" ? "0 0px 0 0" : "0 0px 0 0px",
                  }
            }
          >
            <Box
              position={"relative"}
              height={
                (selectedIcon === "responsive-carousel" && isMobile) ||
                (showPanorama && isMobile) ||
                (selectedIcon === "video" && isMobile)
                  ? "unset"
                  : "60vh"
              }
              border={
                motor?.images?.length === 0 || motor?.socialmedia?.length === 0
                  ? "1px solid #D9D9D9"
                  : "unset"
              }
              borderRadius={"8px"}
            >
              <Grid
                container
                direction="row"
                alignItems={"center"}
                position={"absolute"}
                top={"2%"}
                zIndex={999}
              >
                {/* <Grid item xs>
                    <IconWithBadge
                        title={property?.category_id}
                        badgeStyle={{ padding: '5px 9px', width: 'max-content', borderRadius: '0px 3px 3px 0px', fontSize: 12, background: theme.palette.secondary.main }}
                    />
                </Grid> */}
                <Grid item xs />
                <Grid
                  item
                  xs={6}
                  textAlign={"end"}
                  padding={"0 10px"}
                  style={{ maxWidth: "100%", flexBasis: "unset" }}
                >
                  <Icons.VIDEO
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "video" ? classes.selectedIcon : ""
                    }`}
                    onClick={() => handleIconClick("video")}
                  />
                  <Icons.IMAGE
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "responsive-carousel"
                        ? classes.selectedIcon
                        : ""
                    }`}
                    onClick={() => handleIconClick("responsive-carousel")}
                  />

                  <Icons.FLIP_CAMERA
                    className={`${classes.sliderIcons} ${
                      selectedIcon === "panorama-viewer"
                        ? classes.selectedIcon
                        : ""
                    }`}
                    onClick={() => handleIconClick("panorama-viewer")}
                  />
                </Grid>
              </Grid>
              {showPanorama && motor?.images?.length > 0 && (
                <PanoramaImageViewer
                  src={
                    "https://c7.alamy.com/360/RP99CC/minsk-belarus-august-2017-full-seamless-360-degrees-angle-view-panorama-in-interior-guestroom-hall-with-furniture-in-modern-hotel-in-equirectang-RP99CC.jpg"
                  }
                />
              )}
              {showPanorama && motor?.images?.length === 0 && (
                <MediaNotAvailable
                  child={<Icons.NO_IMAGE />}
                  text={"No 360 Image Found"}
                />
              )}
              {showVideoBox &&
                motor?.socialmedia?.length > 0 &&
                isValidYouTubeUrl(youtubeUrl) && (
                  <iframe
                    width="100%"
                    height="100%"
                    src={youtubeUrl}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ borderRadius: "8px" }}
                  />
                )}
              {showVideoBox &&
                motor?.socialmedia?.length >= 0 &&
                !isValidYouTubeUrl(youtubeUrl) && (
                  <MediaNotAvailable
                    child={<Icons.NO_FILM_MOVIE />}
                    text={"No Recorded Film Found"}
                  />
                )}


              <ImageList variant="quilted" cols={isMobile ? 1 : (motor?.images?.length > 1 ? 3 : 1)}  rowHeight={250} gap={8}>
                {showCarousel && motor?.images?.slice(0, isMobile ? 1 : 3) .map((item, index) => {
                  const imageURL = `${baseURL}${item.image}`;
                  const isSingleImage = isMobile || motor?.images?.length === 1;
                  const isMainImage = index === 0; 
                  const isThirdImage = index === 2;
                  const borderStyles = isSingleImage
                  ? {
                      borderRadius: "16px",
                    }
                  : index === 0 
                  ? {
                      borderTopLeftRadius: i18n.language === "en"? "16px" : "0px",
                      borderBottomLeftRadius: i18n.language === "en"? "16px" : "0px",
                      borderTopRightRadius: i18n.language === "en"? "0px" : "16px",
                      borderBottomRightRadius: i18n.language === "en"? "0px" : "16px",
                    }
                  : {
                      borderTopRightRadius: i18n.language === "en"? "16px" : "0px",
                      borderBottomRightRadius: i18n.language === "en"? "16px" : "0px",
                      borderTopLeftRadius: i18n.language === "en"? "0px" : "16px",
                      borderBottomLeftRadius: i18n.language === "en"? "0px" : "16px",
                    };

                  return (
                    <ImageListItem
                      key={item.id}
                      cols={isMainImage ? 2 : 1}
                      rows={isMainImage ? 2 : 1}
                      style={{
                        cursor: "pointer",
                        overflow: "hidden",
                        ...borderStyles,
                      }}
                    >
                      <img
                        src={imageURL}
                        alt={`Image ${item.id}`}
                        loading="lazy"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={() => openGallery(index)} 
                      />

            {isThirdImage && (
                      <Box
                        sx={{
                        
                            display: "flex",
                            height: "32px",
                            padding: "4px 10px",
                            justifyContent: "center",                       
                            alignItems: "center",
                            gap: "8px",
                            position: "absolute",
                            right: i18n?.language === "en"? "16.596px" : "0 px",
                            left: i18n?.language === "en"? "0 px" : "16.596px",
                            bottom: "13.5px",
                            borderRadius: "8px",
                            background: "rgba(0, 0, 0, 0.6)",
                            zIndex: "2",
                  
                        }}
                      >
                        <ImageIcon sx={{ fontSize: 22, color:"#fff" }} />
                        <Typography variant="h6" sx={{color: "#fff"}}>
                          {motor.images.length} 
                        </Typography>
                      </Box>
                    )}
                    </ImageListItem>
                  );
                })}
              </ImageList>

              {isGalleryOpen && motor?.images?.length > 0 && (
        <Dialog
          open={isGalleryOpen}
          fullWidth
          maxWidth="xl"
          onClose={closeGallery}
          classes={{ paper: "custom-gallery-dialog" }}
          sx={{
            '& .MuiPaper-root':{
              width:"100%",
              maxWidth:"100%",
              background:"#000"
            }
          }}
        >
          <button className="close-button" onClick={closeGallery}>
            <FaXmark size={20} />
          </button>

          <ReactImageGallery
            items={galleryImages}
            startIndex={initialImageIndex}
            showNav={true}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={true}
            thumbnailPosition="bottom"
            renderLeftNav={(onClick, disabled) => (
              <button
                className="nav-button left"
                onClick={onClick}
                disabled={disabled}
              >
             <ChevronLeftIcon />
              </button>
            )}
            renderRightNav={(onClick, disabled) => (
              <button
                className="nav-button right"
                onClick={onClick}
                disabled={disabled}
              >
               <ChevronRightIcon />
              </button>
            )}
          />
        </Dialog>
      )}


            </Box>
          </Grid>
         
        </Grid>
        <Grid
          container
          spacing={2}
          style={
            isMobile
              ? {
                  marginLeft: 0,
                  width: "100%",
                }
              : {
                  flexDirection: "row",
                  display: "flex",
                  height: "100%",
                }
          }
        >
          <Grid
            item
            xs={8}
            style={
              isMobile
                ? {
                    maxWidth: "100%",
                    padding: "0",
                    flexBasis: "unset",
                  }
                : {
                    height: "max-content",
                    padding: "0 15px !important",
                  }
            }
          >
            <Grid
              container
              direction="row"
              alignItems={"center"}
              marginTop={"30px"}
            >
              <Grid item xs>
                <Typography color={theme.palette.primary.main} variant="h4">
                  {motor?.price !== null && motor?.price !== undefined
                                  ? `${formatNumberWithCommas(motor.price)} AED`
                                  : "0 AED"}
                </Typography>
              </Grid>
              <Grid item xs />
              <Grid item xs justifyContent={"end"} display={"flex"} gap={"6px"}>
                {motor?.verified !== undefined && (
                  <IconWithBadge
                    icon={<Icons.VERIFIED />}
                    title={`${motor?.verified} Verified`}
                    badgeStyle={{
                      display: "flex",
                      borderRadius: "60px",
                      background: "#EFEFEF",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "10px 15px",
                      alignItems: "center",
                    }}
                  />
                )}

                <IconWithBadge
                  title= {t('motor-details.featured')}
                  badgeStyle={{
                    padding: "10px 30px",
                    background: theme.palette.primary.blue,
                    color: "#fff !important",
                    fontSize: "11px",
                    borderRadius: "60px",
                    fontWeight: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  titleStyle={{
                    color: "#fff !important",
                  }}
                />
              </Grid>
            </Grid>
            <Box margin={"0px 0"}>
              <IconWithBadge
                icon={<Icons.LOCATION style={{ width: 20, height: 20 }} />}
                title={motor?.emirates}
                badgeStyle={{
                  margin: "8px 0px!important",
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.primary.main,
                }}
                type={"location"}
              />
              <Typography
                fontWeight={400}
                fontSize={35}
                color={theme.palette.primary.main}
                variant="h4"
                sx={{textTransform: "capitalize",
                  fontSize: isMobile ? "20px !important" : "22px !important",
                  fontWeight: "700"}}
              >
                {motor?.title}
              </Typography>
            </Box>
          
            <Typography
              display={"flex"}
              gap={"10px"}
              margin={"25px 0"}
              sx={{ cursor: "pointer", flexWrap: "wrap", }}
            >
              <Box>
                {motor?.year && (
                  <Box style={contactBadgeFeatures}>
                    <Typography sx={{ fontSize: "15px !important" }}>
                    {t('motor-details.year')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px !important",
                        fontWeight: "600 !important",
                      }}
                    >
                      {motor?.year}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box>
                {motor?.specs && (
                  <Box style={contactBadgeFeatures}>
                    <Typography sx={{ fontSize: "15px !important" }}>
                    {t('motor-details.specs')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px !important",
                        fontWeight: "600 !important",
                        overflow: "hidden !important",
                        whiteSpace: "nowrap !important",
                        textOverflow: "ellipsis !important",
                        width: "130px",
                      }}
                    >
                      {motor?.specs}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box>
                {motor?.kilometers && (
                  <Box style={contactBadgeFeatures}>
                    <Typography sx={{ fontSize: "15px !important" }}>
                    {t('motor-details.kilometer')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px !important",
                        fontWeight: "600 !important",
                      }}
                    >
                      {motor?.kilometers}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box>
                {motor?.steering_side && (
                  <Box style={contactBadgeFeatures}>
                    <Typography sx={{ fontSize: "15px !important" }}>
                    {t('motor-details.steering')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "15px !important",
                        fontWeight: "600 !important",
                      }}
                    >
                      {motor?.steering_side}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Typography>

            <Typography
              display={"flex"}
              flexDirection={"column"}
              gap={"12px"}
              margin={"25px 0"}
            >
              {motor?.body_type && (
                <Box display={"flex"} gap={"30px"}>
                  <Typography
                    sx={{
                      flexBasis: "25%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                    }}
                  >
                    {t('motor-details.body')}
                  </Typography>
                  <Typography>{motor?.body_type}</Typography>
                </Box>
              )}

              {motor?.fuel_type && (
                <Box display={"flex"} gap={"30px"}>
                  <Typography
                    sx={{
                      flexBasis: "25%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                    }}
                  >
                    {t('motor-details.fuel')}
                  </Typography>
                  <Typography>{motor?.fuel_type}</Typography>
                </Box>
              )}

              {motor?.seller && (
                <Box display={"flex"} gap={"30px"}>
                  <Typography
                    sx={{
                      flexBasis: "25%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                    }}
                  >
                    {t('motor-details.seller')}
                  </Typography>
                  <Typography>{motor?.seller}</Typography>
                </Box>
              )}

              {motor?.seating_capacity && (
                <Box display={"flex"} gap={"30px"}>
                  <Typography
                    sx={{
                      flexBasis: "25%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                    }}
                  >
                       {t('motor-details.seating')}
                  </Typography>
                  <Typography>{motor?.seating_capacity}</Typography>
                </Box>
              )}

              {motor?.transimission_type && (
                <Box display={"flex"} gap={"30px"}>
                  <Typography
                    sx={{
                      flexBasis: "25%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                    }}
                  >
                    {t('motor-details.transmission')}
                  </Typography>
                  <Typography>{motor?.transimission_type}</Typography>
                </Box>
              )}

              {motor?.engine_capacity && (
                <Box display={"flex"} gap={"30px"}>
                  <Typography
                    sx={{
                      flexBasis: "25%",
                      fontSize: "16px !important",
                      fontWeight: "600",
                    }}
                  >
                       {t('motor-details.engine')}
                  </Typography>
                  <Typography>{motor?.engine_capacity}</Typography>
                </Box>
              )}
            </Typography>
            <Box gap={"20px"} display={"grid"} marginTop={"20px"}>
              {motor?.extras?.length > 0 && (
                <>
                  <Typography
                    color={theme.palette.primary.main}
                    fontWeight={600}
                  >
                    {t('motor-details.extras')}
                  </Typography>
                  <Typography
                    display={"flex"}
                    gap={"20px"}
                    margin={"0"}
                    sx={{ cursor: "pointer", flexWrap: "wrap" }}
                  >
                    {motor?.extras?.length > 0 &&
                      motor?.extras?.map((item, index) => (
                        <Typography
                          key={index}
                          sx={{ background: theme.palette.secondary.main }}
                          borderRadius={"8px"}
                          border={"1px solid #EFEFEF"}
                          padding={"12px"}
                          fontSize={"14px !important"}
                          justifyContent={"center"}
                          color={theme.palette.primary.main}
                        >
                          {item?.title}
                        </Typography>
                      ))}
                  </Typography>
                </>
              )}
            </Box>

            <Box margin={"30px 0"} gap={"20px"} display={"grid"}>
              {motor?.desc && (
                <>
                  <Typography
                    color={theme.palette.primary.main}
                    fontWeight={600}
                  >
                    {t("property-details.description")}
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    {motor?.desc}
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={8}
            sm={12}
            xs={12}
            style={
              isMobile
                ? {
                   paddingLeft: "0px",
                    paddingTop:"0px"
                  }
                : {
                    height: "unset",
                    padding: "0 15px !important",
                    borderRadius: "8px !important",
                    position: "relative",
                  }
            }
          >
             {motor?.user_id && (
              <Box
                paddingTop={"0px !important"}
                marginTop={"30px"}
                marginBottom={"30px"}
              >
              <Box
              borderRadius={"8px !important"}
              boxShadow={"0px 4px 10px rgba(0,0,0,10%)"}
              position={"relative"}
              padding={"10px 0px !important"}
                style={
                  isMobile
                    ? {
                        maxWidth: "100%",
                        padding: "0",
                        marginTop: "20px",
                      }
                    : {
                        
                        padding: "10 0px",
                        marginTop: "0px",
                      }
                }
              >
                <IconWithBadge
                  icon={<Icons.MEMBER />}
                  title={motor?.user_id?.member_since ? t('motor-details.member') : ""}
                  badgeStyle={memberStyles}
                />
                <Box textAlign={"center"}>
                {motor?.user_id?.image ? (
                <img
                  src={`${appURL}${motor?.user_id?.image}`}
                  alt="user"
                  style={{width:"60px", height:"60px", borderRadius:"50px"}}
                  onError={(e) => {
                    e.target.onerror = null; 
                    e.target.src = require("../../assets/icons/new-logo.svg").default;
                    e.target.style.filter = "grayScale(1)";
                    e.target.style.margin = "30px auto";

                  }}
                />
              ) : (
                <Icons.NEW_LOGO style={{ margin: "30px auto", filter: "grayScale(1)" }} />
              )}
                  <Typography
                    style={{ fontSize: "18px", fontWeight: "600", textTransform:"capitalize" }}
                    color={theme.palette.primary.main}
                    variant="h6"
                  >
                    {motor?.user_id?.first_name ? motor.user_id.first_name : ""}{" "}
                    {motor?.user_id?.last_name ? motor.user_id.last_name : ""}
                  </Typography>
             
                  <Box
                    display={"flex"}
                    color={theme.palette.primary.main}
                    fontSize={14}
                    fontWeight={400}
                    marginTop={"25px"}
                    sx={{
                      placeContent: "center",
                      borderTop: "1px solid #D9D9D9",
                      borderBottom: "1px solid #D9D9D9",
                      padding: "12px 0px",
                    }}
                  >
                    <IconWithBadge
                      icon={<Icons.USER />}
                      title={`${motor?.user_id?.member_since}`}
                      badgeStyle={userBadge}
                    />
                    <Typography
                      color={theme.palette.primary.main}
                      dangerouslySetInnerHTML={{
                        __html: `&nbsp; - &nbsp;<span style="text-decoration: underline; font-weight: 700; font-size: 14px; color: #000;">${t('motor-details.total')} ${motor?.user_id?.total_listings} ${t('motor-details.listed')}</span>`,
                      }}
                    />
                  </Box>

                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    margin={"10px 0"}
                    sx={{ cursor: "pointer" }}
                  >
                    {motor?.user_id?.email && (
                      <Link
                        href={`mailto:${motor.user_id.email}`}
                        style={{
                          textDecoration: "unset",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <IconWithBadge
                          icon={<Icons.MAIL />}
                          title={t("property-details.email")}
                          badgeStyle={contactBadge}
                        />
                      </Link>
                    )}
                    {motor?.user_id?.phone && (
                      <Link
                        href={`tel:${motor?.user_id?.phone}`}
                        style={{
                          textDecoration: "unset",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <IconWithBadge
                          icon={<Icons.CALL_PHONE />}
                          title={t("property-details.call")}
                          badgeStyle={contactBadge}
                        />
                      </Link>
                    )}
                    {motor?.user_id?.phone && (
                      <Link
                        href={`https://wa.me/${motor?.user_id?.phone}`}
                        target="blanket"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "unset",
                          color: theme.palette.secondary.main,
                        }}
                      >
                        <IconWithBadge
                          icon={<Icons.WHATSAPP />}
                          title={t("property-details.Whatsapp")}
                          badgeStyle={contactBadge}
                        />
                      </Link>
                    )}
                  </Box>
                  {/* <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    margin={"25px 0 0 0"}
                    sx={{ cursor: "pointer" }}
                  >
                    <IconWithBadge
                      icon={<Icons.REVIEW />}
                      title={t("property-details.review")}
                      badgeStyle={contactBadge}
                      variant={"h6"}
                    />
                    <IconWithBadge
                      icon={<Icons.LIKE />}
                      title={""}
                      badgeStyle={contactBadge}
                      variant={"h6"}
                    />
                    <IconWithBadge
                      icon={<Icons.DISLIKE />}
                      title={""}
                      badgeStyle={contactBadge}
                      variant={"h6"}
                    />
                  </Box> */}

                  {/* <Box
                    display={"flex"}
                    justifyContent={"space-around"}
                    margin={"4px 0 25px 0px"}
                    sx={{ cursor: "pointer" }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      (261) Reviews
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      (200){" "}
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      (31)
                    </Typography>
                  </Box> */}
                </Box>
              </Box>
              </Box>
          )}
            <Box>
              {!isOwner && (
                <Button
                  onClick={handleClickOpen}
                  sx={{
                    background: theme.palette.primary.blue,
                    color: "#fff !important",
                    marginTop: "0px !important",
                    borderRadius: "8px !important",
                    flexDirection:
                      i18n?.language === "en" ? "row" : "row-reverse",
                    "&:hover": {
                      background: theme.palette.primary.blue,
                    },
                  }}
                  className="chat"
                >
                  <ChatBubbleOutlineIcon style={{ marginRight: "10px" }} />{" "}
                  {t("chat")}
                </Button>
              )}
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  component: "form",
                }}
              >
                <DialogTitle>Send a message</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="email"
                    value={textMessage}
                    onChange={(e) => setTextMessage(e.target.value)}
                    type="text"
                    fullWidth
                    variant="standard"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleSendMessage}>Send</Button>
                </DialogActions>
              </Dialog>
            </Box>
            <Box
              item
              xs={4}
              display="flex"
              borderRadius={"8px !important"}
              border={"1px solid #D9D9D9"}
              position={"relative"}
              style={
                isMobile
                  ? {
                      maxWidth: "100%",
                      padding: "15px 10px",
                      marginTop: "40px",
                    }
                  : {
                      height: "unset",
                      padding: "40px",
                      marginTop: "24px",
                    }
              }
            >
              <Box>
                <AdVertical />
              </Box>
            </Box>
            <Box
              item
              xs={4}
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"#F4F4F4"}
              borderRadius={"8px !important"}
              border={"1px solid #D9D9D9"}
              style={
                isMobile
                  ? {
                      maxWidth: "100%",
                      padding: "15px 10px",
                      marginTop: isMobile? "20px" : "40px",
                      height: "unset",
                    }
                  : {
                      height: "236px",

                      marginTop: "24px",
                    }
              }
            >
              <Box sx={{ textAlign: "center" }}>
                <GoogleAds />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} padding={"0px"}></Grid>
        </Grid>

        {
          isMotorLoading && (
            <Loader />
          )
        }
      </Container>
    </>
  );
};
export default MotorDetails;
