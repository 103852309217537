import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Slider,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Menu,
  FormGroup,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FaChevronDown } from "react-icons/fa6";

const restApiUrl = process.env.REACT_APP_REST_API_URL;

const FiltersForm = () => {
  const { t, i18n } = useTranslation("common");
  const countryId = localStorage.getItem("country_id");
  const [fields, setFields] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false)
  const [currentField, setCurrentField] = useState("");

  // Build validation schema dynamically based on field types
  const validationSchema = Yup.object().shape(
    fields.reduce((acc, field) => {
      if (field.type === "dropdown" || field.type === "range") {
        acc[field.name] = Yup.string().required(`${field.label} is required`);
      }
      if (field.type === "checkbox") {
        acc[field.name] = Yup.boolean().required(`${field.label} is required`);
      }
      if (field.type === "slider") {
        acc[field.name] = Yup.number()
          .min(field.min)
          .max(field.max)
          .required(`${field.label} is required`);
      }
      return acc;
    }, {})
  );

  const handleSubmit = (values) => {
    console.log("Form submitted with data: ", values);
  };

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await axios.post(
          `${restApiUrl}/filters`,
          {
            service_type: "motors",
            country_id: countryId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-app-language": i18n?.language,
            },
          }
        );
        setFields(response?.data);
      } catch (err) {
        console.log(err.response?.data?.message || "Failed to fetch filters");
      }
    };

    fetchFilters();
  }, [countryId, i18n.language]);

  const handleClick = (event, fieldName) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setCurrentField(fieldName);
  };

  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget);  
    setOpenMenu((prevState) => !prevState);  
  };
  
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  return (
    <Formik
      initialValues={fields.reduce((acc, field) => {
        acc[field.name] = field.defaultValue || "";
        return acc;
      }, {})}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, handleBlur, setFieldValue }) => (
        <Form>
          <Box sx={{marginTop:"20px",display:"flex",gap:"10px", border:"1px solid #cecece", borderRadius:"12px"}}>
            {fields?.map((field) => {
              switch (field.type) {
                case "dropdown":
                  return (
                    <Box sx={{width:"180px"}}  key={field.field_name}>
                      <div>
                        <Button
                          
                          onClick={(event) =>
                            handleClick(event, field.field_name)
                          }
                          style={{
                            textTransform: "none",
                            width: "100%",
                            dispaly: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <span
                              style={{ fontSize: "13px", fontWeight: "700" }}
                            >
                              {" "}
                              {field.title}
                            </span>

                            <span
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              {field.placeholder}
                            </span>
                          </Typography>

                          <FaChevronDown />
                        </Button>
                        <Menu
                        sx={{

                          '& .MuiList-root':{
                            display:"flex",
                            maxWidth:"340px",
                            flexWrap:"wrap",
                            gap:"10px",
                            paddingLeft:"10px"
                          }
                          
                        }}
                          anchorEl={anchorEl}
                          open={open && currentField === field.field_name}
                          onClose={handleClose}
                          PaperProps={{
                            style: { maxHeight: 400, minWidth: "340px" },
                          }}
                        >
                           {Array.isArray(field.values) &&
                              field.values.map((option) => (
                                <Box key={option.id}>
                                  <Box
                                    sx={{
                                      padding: 1,
                                      border: "1px solid #ccc",
                                      borderRadius: 2,
                                      minWidth: 80,
                                      textAlign: "center",
                                      backgroundColor: "#fff",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      "&:hover": {
                                        backgroundColor: values[
                                          field.field_name
                                        ]?.includes(option.name)
                                          ? "#1976d2"
                                          : "#e1e1e1",
                                      },
                                    }}
                                    onClick={() => {
                                      const updatedValues =
                                        values[field.field_name] || [];
                                      if (updatedValues.includes(option.name)) {
                                        setFieldValue(
                                          field.field_name,
                                          updatedValues.filter(
                                            (item) => item !== option.name
                                          )
                                        );
                                      } else {
                                        setFieldValue(field.field_name, [
                                          ...updatedValues,
                                          option.name,
                                        ]);
                                      }
                                    }}
                                  >
                                    {option.name}
                                  </Box>
                                </Box>
                              ))}
                       
                        </Menu>
                      </div>
                    </Box>
                  );
                case "range":
                  return (
                    <Box sx={{width:"180px"}}  key={field.name}>
                      <Button
                        onClick={(event) =>
                          handleClick(event, field.field_name)
                        }
                        style={{
                          textTransform: "none",
                          width: "100%",
                          dispaly: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <span style={{ fontSize: "13px", fontWeight: "700" }}>
                            {" "}
                            {field.title}
                          </span>

                          <span style={{ fontSize: "14px", fontWeight: "400" }}>
                           {t('filters.placeholder')}
                          </span>
                        </Typography>

                        <FaChevronDown />
                      </Button>

                      <Menu
                        anchorEl={anchorEl}
                        open={open && currentField === field.field_name}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: "152px !important",
                            minWidth: "340px",
                            height:"170px"
                          },
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            backgroundColor: "#fff",
                            border: "1px solid #D1D1D1 ",
                            borderRadius: "8px",
                            padding: "20px",
                            zIndex: "100",
                            boxShadow: "0px 21px 34px rgba(0,0,0,15%)",
                            marginTop: "-8px",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: "13px", alignItems:"end" }}>
                            <Box>
                              <Typography sx={{fontSize:"12px !important"}}>{t("filters.from")}</Typography>

                            <TextField
                              variant="outlined"
                              value={field.extras.min}
                              //   onChange={handleMinInputChange}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 42,
                                  borderRadius: "8px",
                                },
                              }}
                            />
                            </Box>
                           

                            <Typography
                              sx={{
                                border: "1px solid #D1D1D1 ",
                                padding: "20px",
                                borderRadius: "8px",
                                height: "38px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              -
                            </Typography>


                              <Box>
                                <Typography sx={{fontSize:"12px !important"}}>{t("filters.upto")}</Typography>

                              <TextField
                              variant="outlined"
                              value={field.extras.max}
                              //   onChange={handleMaxInputChange}
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 42,
                                  borderRadius: "8px",
                                },
                              }}
                            />
                              </Box>
                            
                          </Box>

                          <Box display={"flex"} justifyContent={"end"}>
                            <Button
                              sx={{
                                background: "#D1D1D1",
                                width: "90px",
                                height: "38px",
                                marginTop: "30px",
                              }}
                            >
                              Done
                            </Button>
                          </Box>
                        </Box>
                      </Menu>
                    </Box>
                  );

                case "checkbox":
                case "slider":
                  return null;
           

                default:
                  return null;
              }
            })}

            <Box sx={{width:"230px"}} >
              <Button
                
                onClick={toggleMenu}
                style={{
                  textTransform: "none",
                  width: "100%",
                  dispaly: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span style={{ fontSize: "13px", fontWeight: "700" }}>
                    {t('filters.filters')}
                  </span>

                  <span style={{ fontSize: "14px", fontWeight: "400" }}>{t('filters.specs')}</span>
                </Typography>

                <FaChevronDown />
              </Button>

              <Menu
                
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: { maxHeight: "400px", maxWidth: "340px", padding:"15px" },
                }}
              >
                <FormGroup>
                  {fields
                    .filter((field) => field.type === "checkbox")
                    .map((checkboxField) => (
                      <FormControlLabel
                      sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
                        key={checkboxField.name}
                        control={
                          <Checkbox
                            checked={values[checkboxField.name] || false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={checkboxField.name}
                          />
                        }
                        label={checkboxField.title}
                      />
                    ))}

                  {fields
                    .filter((field) => field.type === "slider")
                    .map((sliderField) => (
                      <Box
                        key={sliderField.name}
                        sx={{ display: "flex", flexDirection: "column", }}
                      >
                        <Typography sx={{fontSize:"14px !important",fontWeight:"600",marginBottom:"12px"}}>{sliderField.title}</Typography>
                        <Box sx={{display:"flex",flexWrap:"wrap",gap:"5px"}}>
                        {sliderField.values?.map((sliderValue) => (
                          <Box
                            key={sliderValue.id}
                            sx={{
                              padding: 1,
                              border: "1px solid #ccc",
                              borderRadius: 2,
                              
                              textAlign: "center",
                          
                            
                              fontSize: "14px",
                              marginBottom:"12px"
                            }}
                          >
                            {sliderValue.text}
                          </Box>
                        ))} 
                        </Box>
                       
                      </Box>
                    ))}
                </FormGroup>
              </Menu>
            </Box>

           
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default FiltersForm;
