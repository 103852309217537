import React from "react";
import { BounceLoader } from "react-spinners";

const Loader = ({ loading = true, size = 30, color = "#FE820E" }) => {
  return (
    <div style={styles.loaderContainer}>
      <BounceLoader color={color} loading={loading} size={size} />
    </div>
  );
};

const styles = {
  loaderContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)", 
    zIndex: 9999,
  },
};

export default Loader;
