import gql from 'graphql-tag';



const GET_PROPERTY = gql `
    query Property($propertyId: Int) {
  property(id: $propertyId) {
    id
    title
    contact_number
    price
    description
    socialmedia {
      id
      type
      value
    }
    size
    total_closing_fee
    bedrooms
    bathrooms
    developer
    ready_by
    annual_community_fee
    furnished
    emirates
    reference_number
    buyer_transfer_fee
    seller_transfer_fee
    maintenance_fee
    occupancy_status
    amenities {
      id
      title
    }
    posted_by
    user_id {
      id
      image
      first_name
      last_name
      email
      phone
      member_since
      total_listings
    }
    neighbourhood
    location
    category_id
    images {
      id
      image
    }
    is_featured
    is_favorite
    type
  }
}
`;




const GET_PROPERTIES_BY_CATEGORYID = gql`
query Properties($page: Int, $userId: Int, $categoryId: Int, $filters: JSON) {
  properties(page: $page, user_id: $userId, category_id: $categoryId, filters: $filters) {
    total
    per_page
    data {
      annual_community_fee
      bedrooms
      bathrooms
      amenities {
        id
        title
      }
      category_id
      images {
        id
        image
      }
      size
      title
      id
      is_featured
      posted_by
      price
      neighbourhood
      user_id {
        id
      }
    }
    current_page
  }
}
`

export { GET_PROPERTY, GET_PROPERTIES_BY_CATEGORYID };
