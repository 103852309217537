export const HOME = '/';
export const LOGIN_METHODS = '/login-methods';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const OTP_CODE = '/otp';
export const FORGET_PASSWORD = '/forget-password';
export const CREATE_NEW_PASSWORD = '/create-new-password';
export const POST_AD = '/post-ad';
export const CLASSIFIED = '/post-ad/classified';
export const RENT_PROPERTY = '/post-ad/ad/:id';
export const AD_FORM = '/post-ad/form';
export const BUY_PROPERTY = '/post-ad/rent-property/:id';
export const VEHICLE = '/post-ad/vehicle';
export const MOTORS = '/post-ad/rent-property/:id';
export const TRAVELING = '/post-ad/traveling/:id';
export const ELECTRONICS = '/post-ad/electronics/:id';
export const HOME_APPLIANCE = '/post-ad/home-appliances/:id';
export const VENDOR_SERVICES = '/post-ad/vendor-services/:id';
export const MOBILE_AND_TABLETS = '/post-ad/mobile-and-tablets/:id';
export const FURNITURE = '/post-ad/furniture/:id';
export const COMMUNITY = '/post-ad/community/:id';
export const JOBS = '/post-ad/jobs/:id';
export const PROPERTY_AD = '/post-ad/property/:id'
export const PROPERTY_FOR_RENT = '/for-rent/:categoryId';
export const PROPERTY = '/property/:id';
export const CHAT = '/chat'
export const CHAT_BY_ID = '/chat/user-chat/:chat_id'
export const CHAT_DETAILS = '/chat-deatils'
export const PROFILE = '/profile'
export const SEARCHED_ADS = '/searched-ads/:categoryId'
export const MOTOR_DETAILS = '/motors/:id'
export const ALL_MOTORS_ADS = '/motors'
export const CONTACT_US = '/contact-us'
export const ABOUT_US = '/about-us'
export const PRIVACY_POLICY = '/privacy-policy'
export const CAREERS = '/careers'
export const CAREERS_DETAILS = '/career'
export const SELECT_COUNTRY = '/country'
export const MOTOR_SEARCH = '/motor-search/:categoryId'
export const TERMS_CONDITIONS = '/terms-and-conditions'
export const PRICING = "/pricing"
export const FAVORITES = "/favorites"
export const ADS = "/post-ad/ad/:id"
export const NEW_MOTORS = "/new-motors"
export const ALL_PROPERTIES = "/properties"