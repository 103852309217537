import React, { useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Box,
  Stack,
  useMediaQuery,
  Divider,
} from "@mui/material";
import Icons from "../../assets/icons";
import "./footer.scss";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomizedButton from "../customized-button/customized-button.component";
import { Container } from "@mui/system";
import * as ROUTES from "../../constants/routes";
import { Link } from "react-router-dom";
import { useQuery as useApolloQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../../api/queries/categories.queries";
import { useNavigate } from "react-router-dom";
const store = [
  {
    name: "Google Play",
    logo: <Icons.GOOGLE_PLAY />,
    URL: "https://play.google.com/store/apps/details?id=com.cashgate.kulushae",
  },
  {
    name: "App Store",
    logo: <Icons.APPLE />,
    URL: "https://apps.apple.com/ae/app/kulushae/id6478753424",
  },
];
const socialNetwork = [
  {
    name: "Facebook",
    logo: <Icons.FACEBOOK />,
    URL: "#",
  },
  {
    name: "Twitter",
    logo: <Icons.TWITTER />,
    URL: "#",
  },
  {
    name: "Instagram",
    logo: <Icons.INSTAGRAM />,
    URL: "#",
  },
  {
    name: "Youtube",
    logo: <Icons.YOUTUBE />,
    URL: "#",
  },
  {
    name: "LinkedIn",
    logo: <Icons.LINKEDIN />,
    URL: "#",
  },
  {
    name: "Whatsapp",
    logo: <Icons.WHATSAPP />,
    URL: "#",
  },
];
const Footer = () => {
  const { t, i18n } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const {
    loading: isCategoriesLoading,
    error: errorLoadingCategories,
    data: categoriesList,
  } = useApolloQuery(GET_CATEGORIES);

  useEffect(() => {
    console.log("Loading", isCategoriesLoading);
  }, [isCategoriesLoading]);

  useEffect(() => {
    console.log("error loading categories", errorLoadingCategories);
  }, [errorLoadingCategories]);

  const handleCategoryClick = (categoryId) => {
    if (Number(categoryId) === 7) {
      const motorSearchRoute = ROUTES.MOTOR_SEARCH.replace(
        ":categoryId",
        categoryId
      );
      navigate(motorSearchRoute);
    } else {
      const propertyForRentRoute = ROUTES.PROPERTY_FOR_RENT.replace(
        ":categoryId",
        categoryId
      );
      navigate(propertyForRentRoute);
    }
  };
  return (
    <>
   
      <Container>
 
        <Box
          component="footer"
          sx={{
            py: 4,
            position: "relative",
            overflow: "unset",
            width: !isMobile ? "1200px" : "unset",
            margin: "120px auto 0px auto",
          }}
        >
               <Divider/>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            className="top"
            width={"unset"}
            marginLeft={"unset"}
          >
            <Box
              className="pages"
              display={isMobile ? "block" : "flex"}
              flexDirection={!isMobile ? "row" : "unset"}
              alignItems="baseline"
              color={theme.palette.primary.main}
              style={{ marginRight: isMobile ? "unset" : "10px" }}
            >
              <List
                sx={{
                  width: "100%",
                  maxWidth: isMobile ? "unset" : 204,
                  bgcolor: "background.paper",
                  position: "relative",
                  top: 15,
                }}
              >
                <Box className="logo-box" sx={{ textAlignLast: i18n?.language === "en"? "left" : "right" }}>
                  <Icons.NEW_LOGO className="footer-logo" />
                </Box>
                <ListItem sx={{ textAlign: "start", fontSize: "14px" }}>
                  {t("footer.location")}
                </ListItem>
              </List>
              <Box
                className="all-lists"
                display={"flex"}
                width={"100%"}
                gap={isMobile ? "unset !important" : "10px"}
              >
                <List
                  className="list-item"
                  style={{ margin: isMobile ? "unset" : "0 45px" }}
                >
                  <Typography
                    variant={"h4"}
                    fontSize={"20px"}
                    width={"max-content"}
                  >
                    {t("footer.supports.title")}
                  </Typography>
                  <ListItem
                    sx={{
                      fontSize: "14px",
                      "& :hover": {
                        boxShadow: `0px -1px 0px 0px ${theme.palette.primary.orange} inset`,
                      },
                    }}
                  >
                    <Link
                      to={ROUTES.CONTACT_US}
                      style={{
                        textDecoration: "none",
                        color: `${theme.palette.primary.main}`,
                      }}
                    >
                      {t("header.contact")}
                    </Link>
                  </ListItem>
                </List>
                <List
                  className="list-item"
                  style={{ margin: isMobile ? "unset" : "0 45px" }}
                >
                  <Typography
                    variant={"h4"}
                    fontSize={"20px"}
                    width={"max-content"}
                  >
                    {t("footer.quick-links.title")}
                  </Typography>
                  <ListItem
                    sx={{
                      fontSize: "14px",
                      "& :hover": {
                        boxShadow: `0px -1px 0px 0px ${theme.palette.primary.orange} inset`,
                      },
                    }}
                  >
                    <Link
                      to={ROUTES.ABOUT_US}
                      style={{
                        textDecoration: "none",
                        color: `${theme.palette.primary.main}`,
                      }}
                    >
                      {t("header.about")}
                    </Link>
                  </ListItem>

                  <ListItem
                    sx={{
                      fontSize: "14px",
                      "& :hover": {
                        boxShadow: `0px -1px 0px 0px ${theme.palette.primary.orange} inset`,
                      },
                    }}
                  >
                    <Link
                      to={ROUTES.SELECT_COUNTRY}
                      style={{
                        textDecoration: "none",
                        color: `${theme.palette.primary.main}`,
                      }}
                    >
                      {t("footer.quick-links.post-ads")}
                    </Link>
                  </ListItem>
                </List>
                <List
                  className="list-item"
                  style={{ margin: isMobile ? "unset" : "0 70px" }}
                >
                  <Typography
                    variant={"h4"}
                    fontSize={"20px"}
                    width={"max-content"}
                  >
                    {t("header.category")}
                  </Typography>
                  {categoriesList &&
                    categoriesList.categories
                      .filter((category) => category.active_for_listing)
                      .map((category) => (
                        <ListItem
                          sx={{
                            fontSize: "14px",
                            cursor: "pointer",
                            width: "fit-content",
                            "&:hover": {
                              boxShadow: `0px -1px 0px 0px ${theme.palette.primary.orange} inset`,
                            },
                          }}
                          key={category.id}
                          onClick={() => handleCategoryClick(category.id)}
                        >
                          {category.title}
                        </ListItem>
                      ))}
                </List>
              </Box>
              <Box
                className="our-app"
                style={{ marginLeft: isMobile ? "unset" : "70px" }}
              >
                <Typography
                  className="h4"
                  variant={"h4"}
                  fontSize={"20px"}
                  width={"max-content"}
                >
                  {t("footer.download.title")}
                </Typography>
                <Box
                  className="store-box"
                  display="flex"
                  gap="12px"
                  margin="33px 0px 20px 0px;"
                >
                  {store.map((item, index) => (
                    <Box key={index}>
                      <a
                        href={item.URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <CustomizedButton
                          className="store-btn"
                          {...item}
                          title={
                            item?.name === "Google Play"
                              ? t("footer.download.google-play")
                              : item?.name === "App Store"
                              ? t("footer.download.app-store")
                              : ""
                          }
                          name={t("footer.download.get-now")}
                          logo={item?.logo}
                          btnStyle={{
                            borderRadius: 8,
                            border: "1px solid var(--grayscale-50, #EBEEF7)",
                            padding: 10,
                            lineHeight: "unset",
                            marginRight: "7px !important",
                            gap: 15,
                            textTransform: "capitalize",
                            textAlign: "start",
                            backgroundColor: "#fff",
                          }}
                          nameStyle={{
                            color: "var(--grayscale-500, #767E94)",
                            fontSize: "12px",
                          }}
                          titleStyle={{
                            color: theme.palette.primary.main,
                            fontSize: "16px",
                          }}
                          sx={{
                            "&:hover": {
                              boxShadow:
                                "0px 12px 48px 0px rgba(0, 44, 109, 0.10)",
                              border: "1px solid #fff !important",
                            },
                          }}
                        />
                      </a>
                    </Box>
                  ))}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  position="relative"
                  className="social-bar"
                >
                  {socialNetwork.map((item, index) => (
                    <Box key={index} className="social-icon">
                      <a href={item.URL}>{item.logo}</a>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={{
              xs: isMobile ? "center" : "normal",
              md: "space-evenly",
            }}
            flexWrap="wrap"
            padding="2rem 0"
            display="flex"
            alignItems="center"
            textAlign={isMobile ? "center" : "unset"}
            margin={isMobile ? "0 auto" : "unset"}
          >
            <Typography
              sx={{ opacity: 1, fontSize: "14px !important" }}
              color="#000"
            >
              {`${t("title")} - ${t(
                "footer.copyrights.classification"
              )} © ${new Date().getFullYear()}. ${t(
                "footer.copyrights.rights-reserved"
              )}.`}
            </Typography>
            <Typography
              sx={{ opacity: 1, fontSize: "14px !important" }}
              style={{ marginLeft: isMobile ? "unset" : "16px" }}
              color="#000"
            >
              <Link className="link" to={ROUTES.PRIVACY_POLICY}>
                {t("footer.copyrights.privacy-policy")}
              </Link>{" "}
              |{" "}
              <Link className="link" to={ROUTES.TERMS_CONDITIONS}>
                {t("footer.copyrights.terms-conditions")}
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Container>
    </>
  );
};
export default Footer;
