import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/authSlice';
import conversationReducer from './slices/conversationSlice';
import favoriteReducer from './slices/favoriteSlice'
import locationReducer from './slices/locationSlice'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], 
};

const rootReducer = combineReducers({
  auth: authReducer,
  conversations: conversationReducer,
  favorites: favoriteReducer,
  location: locationReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;


// import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import authReducer from './slices/authSlice';
// import conversationReducer from './slices/conversationSlice';
// import countryReducer from './slices/countrySlice';

// const rootReducer = combineReducers({
//   auth: authReducer,
//   conversations: conversationReducer,
//   country: countryReducer,
// });

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

// export default store;
