import FormBox from "../../../components/form-box/form-box.component";
import FormInput from "../../../components/form-input/form-input.component";
import { useTranslation } from "react-i18next";
import Icons from "../../../assets/icons";
import * as yup from "yup";
import { useState } from "react";
import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { EMAIL_REGISTER_MUTATION } from "../../../api/queries/auth.queries";
import Toaster from "../../../components/toaster/toaster.component";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as ROUTES from '../../../constants/routes';
import Loader from '../../../components/loader/Loader'

const EmailRegister = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate()
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const EmailRegisterSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("authentication.register.first_name_required")),
    lastName: yup
      .string()
      .required(t("authentication.register.last_name_required")),
    email: yup
      .string()
      .email(t("authentication.login.email_format"))
      .required(t("authentication.login.email_required")),
    password: yup
      .string()
      .required(t("authentication.login.password_required")),
  });
  const [emailRegisterMutation] = useMutation(EMAIL_REGISTER_MUTATION);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
    },
    validationSchema: EmailRegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmitEmailRegister(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
  });
  const onSubmitEmailRegister = async (event) => {
    event.preventDefault();
    try {
      const { firstName, lastName, password, email } = formik.values;
      formik.setTouched({
        firstName: true,
        lastName: true,
        password: true,
        email: true,
      });
      EmailRegisterSchema.validateSync(
        { firstName, lastName, password, email },
        { abortEarly: false }
      );
      let type = "email";
      let registerId = email;

      const response = await emailRegisterMutation({
        variables: { firstName, lastName, password, email, type, registerId },
      });
      setLoading(true);
      setTimeout(() => {
        const userData = JSON.stringify(response.data);
        localStorage.setItem("userData", userData);
        formik.resetForm();
        formik.submitForm();
        setLoading(false)
        setOpenSuccess(true);
        setTimeout(() => {
          setOpenSuccess(false);
          navigate(ROUTES.LOGIN);
        }, 2000)
      },2000)
      
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        formik.setErrors(validationErrors);
      } else {
        const { message } = error;
        setErrorMessage(message);
        setLoading(false);
        setOpenAlert(true);
      }
    }
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  return (
    <>
      {errorMessage && (
        <Collapse in={openAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <Icons.CLOSE />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>
      )}
      <FormBox
        onSubmit={(event) => onSubmitEmailRegister(event)}
        sx={{ display: "grid" }}
        submitValue={t("authentication.register.sign_up")}
        submitStyle={{
          borderRadius: "8px",
          border: `1px solid ${theme.palette.primary.main}`,
          background: theme.palette.primary.orange,
          color: theme.palette.secondary.main,
          textTransform: "capitalize",
          margin: "25px 0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("firstName")}
                name="firstName"
                required
                fullWidth
                type="text"
                error={
                  formik.touched["firstName"] &&
                  Boolean(formik.errors["firstName"])
                }
                helperText={
                  formik.touched["firstName"] && formik.errors["firstName"]
                }
                label={t("authentication.register.first_name")}
                value={
                  formik.values && formik.values["firstName"]
                    ? formik.values["firstName"]
                    : ""
                }
                icon={<Icons.USER_DATA style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("lastName")}
                name="lastName"
                required
                fullWidth
                type="text"
                error={
                  formik.touched["lastName"] &&
                  Boolean(formik.errors["lastName"])
                }
                helperText={
                  formik.touched["lastName"] && formik.errors["lastName"]
                }
                label={t("authentication.register.last_name")}
                value={
                  formik.values && formik.values["lastName"]
                    ? formik.values["lastName"]
                    : ""
                }
                icon={<Icons.USER_DATA style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("email")}
                name="email"
                required
                fullWidth
                type="email"
                error={
                  formik.touched["email"] && Boolean(formik.errors["email"])
                }
                helperText={formik.touched["email"] && formik.errors["email"]}
                label={t("authentication.login.social.email")}
                value={
                  formik.values && formik.values["email"]
                    ? formik.values["email"]
                    : ""
                }
                icon={<Icons.MAIL style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("password")}
                name="password"
                required
                fullWidth
                // placeholder={t("authentication.login.password")}
                type="password"
                error={
                  formik.touched["password"] &&
                  Boolean(formik.errors["password"])
                }
                helperText={
                  formik.touched["password"] && formik.errors["password"]
                }
                label={t("authentication.login.password")}
                value={
                  formik.values && formik.values["password"]
                    ? formik.values["password"]
                    : ""
                }
                icon={<Icons.LOCK style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
        </Grid>
      </FormBox>
      {
        loading && (
          <div>
          <Loader/>
          </div>
        )
      }
      <Toaster
        open={openSuccess}
        title={"Congratulations!"}
        description={"You’ve Successfully Registered!"}
        handleClose={handleCloseSuccess}
        icon={<Icons.SUCCESS style={{ margin: "15px 0" }} />}
      />
    </>
  );
};
export default EmailRegister;
