import React, { useEffect, useState} from 'react'
import ConversationItem from '../conversation-item/ConversationItem'
import { GET_CHATS } from '../../../api/queries/chat.queries';
import { useQuery } from '@apollo/client';
import './chatlist.scss'
import { CircleLoader   } from 'react-spinners';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { Box } from '@mui/material';
import { useDispatch , useSelector} from 'react-redux';
import { addConversation } from '../../../store/slices/conversationSlice'
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../../loader/Loader';


const ConversationList = ({ isEditMode, onClick, handleDeleteChat }) => {

  const { t, i18n} = useTranslation("common")

  const { chat_id } = useParams();
  const dispatch = useDispatch();
  const isLoggedin = localStorage.getItem('isLoggedIn')
  
  const {chats} = useSelector((state) => state.conversations)


  const [selectedChat, setSelectedChat] = useState(null)


  const { loading: isLoadingChats, error: errorLoadingChats, data: chatsdata } = useQuery(GET_CHATS);

  useEffect(() => {
    console.log('loading')
  }, [isLoadingChats]
  )

  useEffect(() => {
    console.log('error', errorLoadingChats)
  }, [errorLoadingChats])

  const handleChatSelect = (chat) => {
    setSelectedChat(chat);
    onClick(chat)
  };

  const handleAddNewConversation = (chat) => {
    dispatch(addConversation(chat));
  }


  useEffect(() => {
    if (chat_id && chatsdata && chatsdata.getChats) {
      const selectedChat = chatsdata.getChats.find(chat => chat.id === chat_id);

      if (selectedChat) {
        setSelectedChat(selectedChat);
        onClick(selectedChat)
      }
    }
  }, [chat_id, chatsdata]);
  


  if (isLoadingChats) return <Loader />

  return (
    <Box className='chat-list'
    sx={{
      ...(i18n?.language === "en" 
        ? { borderRight: '1px solid #f2f2f2' } 
        : { borderLeft: '1px solid #f2f2f2' }
      ),
      paddingRight: '40px'
    }}
    >

      {
        chatsdata && chatsdata.getChats && chatsdata.getChats.length > 0 ? (
          chatsdata.getChats.map((chat) => (
            <ConversationItem 
            key={chat.id}
             chat={chat} 
             onClick={() => handleChatSelect(chat)}
             isEditMode={isEditMode}
             handleDeleteChat={handleDeleteChat}
             onAddConversation={() => handleAddNewConversation(chat)}
             />
          ))
        ) : (
          <Box style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            verticalAlign: 'middle'
          }}>
            <ChatBubbleIcon style={{
              margin: '0 auto',
              
            }} />
            
            {t('chat-details.no-chats')}
            
            </Box>
        )
        }
      
    </Box>
  )
}

export default ConversationList
