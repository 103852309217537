import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import InputEmoji from "react-input-emoji";
import SendIcon from "@mui/icons-material/Send";
import "./chatform.scss";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import axios from "axios";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { pusher } from "../../pusher/pusher-config";
import { useSelector } from "react-redux";
import { UPLOAD_IMAGES } from "../../api/queries/form.queries";
import { useMutation as useReactQueryMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { useQuery } from "@apollo/client";
import { GET_MESSAGES } from "../../api/queries/chat.queries";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import ImageIcon from "@mui/icons-material/Image";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";


const apiURL = process.env.REACT_APP_REST_API_URL;
const appURL = process.env.REACT_APP_BASE_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
  display: "flex",
  justifyContent: "center",
  gap: "30px",
  border: "none",
  borderRadius: "15px",
};

const ChatForm = ({ selectedChat, onMessageChange, setMessages }) => {
  const fileInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [ isUploading, setIsUploading ] = useState(false)
  const [ previewImage, setPreviewImage] = useState(null)
  const { t, i18n } = useTranslation("common")
  const { receiverId, senderId } = useSelector((state) => state.conversations);
  const storedUser = localStorage.getItem("userData");
  const parsedUserData = storedUser ? JSON.parse(storedUser) : null;
  const userId = parsedUserData ? parsedUserData.id : null;
  const currentUser = parseInt(userId);
  const [recordingTime, setRecordingTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChooseFile = () => {
    fileInputRef.current.click();
    setOpen(false);
  };

  const handleChooseVideo = () => {
    videoInputRef.current.click();
    setOpen(false);
  };

  const [isRecording, setIsRecording] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState([]);
  const [video, setVideo] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);

  // get messages

  let queryVariables = null;
  if (selectedChat) {
    const { sender_id, receiver_id, category_id, item_id, id } = selectedChat;

    queryVariables = {
      chatId: parseInt(id),
      senderId: parseInt(sender_id),
      receiverId: parseInt(receiver_id),
      categoryId: parseInt(category_id),
      itemId: parseInt(item_id),
    };
  }

  const { loading, error, data, refetch } = useQuery(GET_MESSAGES, {
    variables: queryVariables,
    skip: !queryVariables,
  });

  const handleTextMessageChange = (text) => {
    setTextMessage(text);
    onMessageChange(text);
  };

  // function to choose and upload images

  const uploadImagesFunc = useReactQueryMutation(UPLOAD_IMAGES);
  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result)
    };
    reader.readAsDataURL(file);

    if (file) {
      const data = new FormData();

      console.log(data, "from data");
      data.append("type", "CHAT_IMAGES_URL");
      data.append("file", file);
      data.append("chat_id", selectedChat.id);
      try {
        setIsUploading(true)
        const response = await uploadImagesFunc.mutateAsync(data);
        const file_name = response["file_name"];
        const file_url = response["url"];
        const key = uuidv4();
        const image = `${file_name}`;
        setImage(image);
        setIsUploading(false)
      } catch (error) {
        console.log(error.response?.data.error);
        setIsUploading(false)
        setPreviewImage(null)
      }
    }
  };

  // function to choose and upload videos

  const MAX_VIDEO_SIZE = 10 * 1024 * 1024;
  const handleVideoChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > MAX_VIDEO_SIZE) {
        // Notify the user that the file exceeds the size limit
        alert("Selected video exceeds the maximum file size limit (10MB)");
        return;
      }

      const data = new FormData();
      data.append("type", "CHAT_IMAGES_URL");
      data.append("file", file);
      data.append("chat_id", selectedChat.id);
      try {
        const response = await uploadImagesFunc.mutateAsync(data);
        const file_name = response["file_name"];
        const video = `${file_name}`;
        setVideo(video);
      } catch (error) {
        console.log(error.response?.data.error);
      }
    }
  };

  // Recording the voice messages

  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const startRecording = async () => {
    setIsRecording(true);
    setRecordingTime(0);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/mp3" });

        const audioFile = new File([recordedBlob], "audio.mp3", {
          type: "audio/mp3",
        });

        setAudioBlob(audioFile);
        chunks.current = [];
      };
      mediaRecorder.current.start();

      const id = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(id);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setIsRecording(false);
  };
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" + secs : secs}`;
  };

  useEffect(() => {

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [intervalId]);


  const handleAudioChange = async (audioBlob) => {
    if (!audioBlob) {
      console.error("No audio blob available");
      return;
    }
    const data = new FormData();
    data.append("type", "CHAT_IMAGES_URL");
    data.append("file", audioBlob);
    data.append("chat_id", selectedChat.id);

    try {
      const response = await uploadImagesFunc.mutateAsync(data);
      const file_name = response["file_name"];
      const audioUrl = response["url"];

      setAudioBlob(file_name);

      await handleSendMessage(audioBlob);
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  useEffect(() => {
    if (audioBlob) {
      handleAudioChange(audioBlob);
    }
  }, [audioBlob]);

  // sending messages
  const handleSendMessage = async () => {
    // if (!textMessage && !image && !video && !audioBlob) {
    //   return;
    // }

    const { id, category_id, item_id } = selectedChat;

    if (textMessage || audioBlob || image || video) {
      try {
        const token = localStorage.getItem("token");

        const formData = new FormData();
        formData.append("chat_id", id);
        formData.append("category_id", category_id);
        formData.append("item_id", item_id);
        formData.append("receiver_id", receiverId);
        formData.append("sender_id", senderId);

        let messageType = "";
        let messageContent = "";

        if (textMessage) {
          messageType = "text";
          messageContent = textMessage;
        }

        if (image) {
          messageType = "image";
          messageContent = image;
        }

        if (video) {
          messageType = "video";
          messageContent = video;
        }

        if (audioBlob) {
          messageType = "voice";
          messageContent = audioBlob;
        }
        formData.append("message", messageContent);
        formData.append("type", messageType);
        const response = await axios.post(
          `${apiURL}/pusher/send-message`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setTextMessage('');
          setAudioBlob(null);
          setVideo(null);
          setImage(null);
          setPreviewImage(null);
         setIsUploading(false);
          const newMessage = {
            id: uuidv4(),
            sender_id: senderId,
            receiver_id: receiverId,
            message: messageContent,
            type: messageType,
            createdAt: Date.now(),
            __typename: "Message",
            ...(messageType === "text" && { text: textMessage }),
            ...(messageType === "voice" && { voice: audioBlob }),
            ...(messageType === "image" && { image: image }),
            ...(messageType === "video" && { message: video }),
          };

          setMessages((prevMessages) => [...prevMessages, newMessage]);
          refetch();
        } else {
          console.error("Failed to send message:", response.statusText);
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  // useEffect(() => {

  //   const { category_id, item_id, receiver_id } = selectedChat;
  //   const channelName = `private-chat_channel-${category_id}${item_id}${userId}${receiver_id}`;
  //   const channel = pusher.subscribe(channelName);

  //   channel.bind("chat", (data) => {
  //     setMessages((prevMessages) => [...prevMessages, data]);
  
  //     if (data.type === "voice" && data.sender_id !== currentUser) {
  //       playVoiceMessage(data.voice);
  //     }
  //   });
  
  //   return () => {
  //     pusher.unsubscribe(channelName);
  //   };
  // }, [selectedChat, currentUser, setMessages]);

  useEffect(() => {
    const { category_id, item_id, receiver_id } = selectedChat;
    const channelName = `private-chat_channel-${category_id}${item_id}${userId}${receiver_id}`;
    const channel = pusher.subscribe(channelName);
  
    channel.bind("chat", (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
  
      if (data.type === "voice" && data.sender_id !== currentUser) {
        playVoiceMessage(data.voice);
      }
    });
  
    return () => {
      channel.unbind("chat");
      pusher.unsubscribe(channelName);
    };
  }, [selectedChat, currentUser, setMessages, userId, pusher]);
  
  
  // Function to play a voice message
  // const playVoiceMessage = (voiceUrl) => {
  //   if (voiceUrl) {
  //     const audio = new Audio(`${apiURL}/${voiceUrl}`);
  //     audio.play().catch((error) => {
  //       console.error("Error playing voice message:", error);
  //     });
  //   }
  // };
  const playVoiceMessage = async (voiceUrl) => {
    try {
      const response = await fetch(`${apiURL}/${voiceUrl}`);
      if (response.ok) {
        const audio = new Audio(response.url);
        audio.play();
      } else {
        console.error("Audio file not found:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching audio file:", error);
    }
  };
  
  useEffect(() => {
    if (audioBlob) {
      playVoiceMessage(audioBlob);
    }
  }, [audioBlob]);

  const handleKeyDown = (e) => {
    if(e.key === "Enter" && textMessage.trim() !== ''){
      handleSendMessage()
    }
  };




  return (

    <Box position={"relative"}>
    <Box
      display={"flex"}
      gap={"15px"}
      alignItems={"center"}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "15px",
        marginTop: "10px",
        padding: "12px",
        boxShadow: "0px 4px 10px rgba(0,0,0,.16)",
      }}
    >
      <InputEmoji
        value={textMessage}
        onChange={handleTextMessageChange}
        cleanOnEnter
        placeholder={t('chat-details.input-placeholder')}
        style={{
          color: "#32363f",
        }}
        onKeyDown={handleKeyDown}
      />
       
      

      <PhotoCameraIcon style={{ color: "#32363f" }} onClick={handleOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Button
              onClick={(e) => handleChooseFile(e)}
              sx={{
                background: "#32363f",
                borderRadius: "100%",
                width: "100px",
                height: "100px",
                "&:hover": {
                  background: "#000",
                },
              }}
            >
              <ImageIcon sx={{ color: "#fff", fontSize: "60px" }} />
         
            </Button>
            <Button
              sx={{
                background: "#32363f",
                borderRadius: "100%",
                width: "100px",
                height: "100px",
                "&:hover": {
                  background: "#000",
                },
              }}
              onClick={(e) => handleChooseVideo(e)}
            >
              <VideoCameraBackIcon sx={{ color: "#fff", fontSize: "60px" }} />
            </Button>
          </Box>
        </Fade>
      </Modal>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />

      <input
        type="file"
        accept="video/mp4"
        onChange={handleVideoChange}
        style={{ display: "none" }}
        ref={videoInputRef}
      />
      {isRecording ? (
        <PauseCircleOutlineIcon
          style={{ color: "red" }}
          onClick={stopRecording}
        />
      ) : (
        <KeyboardVoiceIcon
          style={{ color: "#32363f" }}
          onClick={startRecording}
        />
      )}

      <div>
      {isRecording && (
        <div>
          <span> {formatTime(recordingTime)}</span>
        </div>
      )}
      </div>

      <SendIcon
        onClick={handleSendMessage}
        style={{
          color: textMessage || audioBlob ? "#000" : "#b6b6b6",
          cursor: textMessage || audioBlob ? "pointer" : "default",
          transform: i18n?.language === "en"? 'rotate(0deg)' : 'rotate(-180deg)'
        }}
      />

  {/* Image preview during upload */}
  {previewImage && (
        <Box
          sx={{
            position: "absolute",
            width: "100px",
            height: "100px",
            top:"-110px",
            marginBottom: "10px",
          }}
        >
          <img
            src={previewImage}
            alt="Preview"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
              opacity:  isUploading ? 0 : 1,
            }}
          />
          {/* {isUploading && <Typography>Uploading...</Typography>} */}
        </Box>
      )}
    </Box>
    </Box>
  );
};

export default ChatForm;
