import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Autocomplete,
  Container,
  Button

} from "@mui/material";
import SectionTitle from "../section-title/section-title.component";
import ControlledButton from "../Button/button.component";
import CustomSelect from "../custom-select/custom-select.component";
import Icons from "../../assets/icons";
import Images from "../../assets/images";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GET_CATEGORIES } from "../../api/queries/categories.queries";
import { SEARCH } from "../../api/queries/home.queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import GetStartedEn from "../../assets/images/get-started-en.component";
import GetStartedAr from "../../assets/images/get-started-ar.component";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import "./hero.scss";
import InputAdornment from "@mui/material/InputAdornment";
import { useCategory } from "../../context/CategoryContext";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const Hero = ({ categories }) => {
  const { i18n, t } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { selectedCategory } = useCategory();
  const [searchValue, setSearchValue] = useState("");
  const { getSelectedCategoryServiceType } = useCategory();
  const serviceType = getSelectedCategoryServiceType();

  let someStyles = {
    textAlign: "center",
    backgroundColor: theme.palette.primary.orange,
    color: theme.palette.secondary.main,
    textWrap: "nowrap",
    textTransform: "capitalize",
    borderRadius: 41,
    boxShadow: "0px 4px 32px 0px rgba(104, 104, 104, 0.99)",
    zIndex: isMobile ? "unset" : "9999",
    position: "absolute",
  };
  let getStartedStyles = isMobile
    ? {
        ...someStyles,
        right: i18n?.language === "en" ? "0" : "auto",
        left: i18n.language === "en" ? "auto" : "-32.5px",
        width: i18n.language === "en" ? "unset" : "90px",
      }
    : {
        ...someStyles,
        right: i18n?.language === "en" ? "-32.5px" : "auto",
        left: i18n.language === "en" ? "auto" : "-32.5px",
      };


     
      const [search, { loading, error, data }] = useLazyQuery(SEARCH);
    
      useEffect(() => {
        if (serviceType && searchValue) {
        
          search({ variables: { value: searchValue, serviceType: serviceType } });
        }
      }, [serviceType, searchValue, search]);   useEffect(() => {
        if (serviceType && searchValue) {
          search({ variables: { value: searchValue, serviceType: serviceType } });
        }
      }, [serviceType, searchValue, search]);
    
      const handleSearchChange = (event) => {
        const value = event?.target?.value;
        setSearchValue(value);
      };

  const searchResults = data?.search || [];

  const handleAdClick = (categoryId) => {
    navigate(`${ROUTES.SEARCHED_ADS.replace(":categoryId", categoryId)}`);
  };
  


  const handleGetStartedClick = () => {
    if(serviceType === "property"){
      navigate(ROUTES.ALL_PROPERTIES)
    }
    else{
      navigate(ROUTES.ALL_MOTORS_ADS)
    }
  }


  const [open, setOpen] = useState(true);
  return (
    <Stack
      width="100%"
      marginTop={isMobile ? "1rem" : "unset"}
      position={"relative"}
    >
      <Box
        position={"absolute"}
        top={"50%"}
        left={0}
        sx={{ transform: "translate(0, -50%)" }}
      >
        <Icons.SM_HALF_CIRCLE />
      </Box>
      <Stack
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flex={1} display={"flex"}>
          <Box
            position={"relative"}
            left={i18n?.language === "en" ? 66 : "unset"}
            top={i18n?.language === "en" ? 20 : "unset"}
          >
            <Icons.SM_CUBE />
          </Box>
          {/* <Box
            marginLeft={i18n?.language === "en" ? "auto" : "unset"}
            marginRight={i18n?.language === "en" ? "unset" : "auto"}
            position={"relative"}
          >
            <Icons.SM_CIRCLES style={{ marginRight: "35px" }} />
          </Box> */}
        </Box>
        <Box flex={1}></Box>
      </Stack>
        <Container>
      <Stack
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        maxWidth={1320}
        margin={isMobile ? "unset" : "0 auto"}
        padding={0}
        width={"100%"}
      >
        <Box flex={1} width={isMobile ? "100%" : "50%"}>
          <Box
            width="100%"
            padding={
              isMobile && i18n.language === "ar"
                ? "0 15px 0 45px"
                : "0 45px 0 15px"
            }
          >
            <SectionTitle variant={"h1"} title={t("hero.intro")} />
            <Typography
              variant="h1"
              color={theme.palette.primary.main}
              sx={{
                textShadow: "none !important",
              }}
            >
              {t("hero.intro1")}
            </Typography>
          </Box>
          <Box
            padding={isMobile && i18n.language === "ar" ? "0 15px 0 45px" : "0"}
            position={"relative"}
          >
            <Box
              marginTop={isMobile ? "40px !important" : "70px !important"}
              width={"100%"}
              padding={"0 10px"}
            >
              <Stack
                direction="row"
                display="flex"
                padding={isMobile ? "5px 0" : "7px 0px !important"}
                borderRadius={90}
                sx={{ background: "#F3F3F3" }}
                width={isMobile ? "100%" : "unset"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"6px"}
                  padding={isMobile ? "0 6px" : "0 15px"}
                  sx={{ cursor: "pointer" }}
                ></Box>
                <Box className="search_box_wrapper">
                  <Box className="search_box_item">
                    <Box className="search_box">
                      <Autocomplete
                        freeSolo
                        options={searchResults}
                        getOptionLabel={(option) => option.title || ""}
                        inputValue={searchValue}
                        onInputChange={handleSearchChange}
                        slotProps={{
                          paper: {
                            sx: {
                              "&. MuiAutocomplete-endAdornment": {
                                display: "none",
                              },
                              "&. MuiPopper-root":{
                                  zIndex:"-1 !important"
                              },
                              "& .MuiAutocomplete-listbox": {
                                height: "220px",
                                overflowY: "scroll",
                                ":-webkit-scrollbar": {
                                  display: "none",
                                },
                                "-ms-overflow-style": "none",
                                "scrollbar-width": "none",
                                "& .MuiAutocomplete-option": {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                },
                              },
                              borderTopLeftRadius: "0px",
                              borderTopRightRadius: "0px",
                              borderBottomLeftRadius: "8px",
                              borderBottomRightRadius: "8px",
                              marginTop: "-15px",
                              marginLeft: "2px",
                              paddingTop: "10px",
                              positon: "relative",
                              zIndex: "-1 !important",
                              boxShadow: "rgba(0, 0, 0, 0.1) 0px 21px 34px",
                            },
                          },
                        }}
                        componentsProps={{
                          sx: {
                            "& ::-webkit-scrollbar": {
                              display: "none !important",
                            },
                            "& .MuiAutocomplete-listbox": {
                              display: "flex",
                              justifyContent: "space-between",
                              height: "381px",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              minWidth:
                                i18n.language === "en" ? "530px" : "530px",
                              zIndex:"-1 !importnat",
                            
                            }}
                           
                            {...params}
                            className="search-field"
                            placeholder={t("search-placeholder")}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Icons.SEARCH />
                                </InputAdornment>
                              ),
                              sx: {
                                background: "#fff",
                                borderRadius: "60px !important",
                                color: "#000",
                                marginTop: "8px",
                                paddingLeft: "5px",
                                width: "100% !important",
                                "& fieldset": {
                                  border: "none",
                                },
                                "& .MuiInputBase-input": {
                                  height: "unset",
                                },
                              },
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <Box
                            className="result-box"
                            {...props}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "10px",
                              border: "none",
                              boxShadow: "none",
                            }}
                            onClick={() => handleAdClick(option.category_id)}
                          >
                            <Typography
                              sx={{
                                fontSize: "16px !important",
                                fontWeight: "500",
                                color: "#000",
                                textTransform: "capitalize",
                              }}
                            >
                              {option.title}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "16px !important",
                                backgroundColor: "#EEEEEE",
                                padding: "10px",
                                borderRadius: "10px",
                                width: "52px",
                                height: "35px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {option.ads}
                            </Typography>
                          </Box>
                        )}
                      />
                    </Box>

                    
                    <ControlledButton
                      customStyle={getStartedStyles}
                      value={t("hero.start")}
                      onClick={handleGetStartedClick}
                    />
                  </Box>
                </Box>
              </Stack>
            </Box>
            <Box
              style={{
                textAlign: "end",
                position: "relative",
                transform:
                  i18n.language === "en" ? "rotate(-0.711deg)" : "unset",
                right: isMobile && i18n.language === "en" ? "30px" : "unset",
              }}
            >
              {i18n.language === "en" ? (
                <GetStartedEn
                  text={t("hero.get-started")}
                  width={isMobile ? "320" : "384"}
                />
              ) : (
                <GetStartedAr
                  text={"ابدأ في تلبية حاجتك"}
                  width={isMobile ? "320" : "384"}
                />
              )}
            </Box>
          </Box>
        </Box>
        {!isMobile && (
          <Box
            className="hero-image-box"
            flex={1}
            width="50%"
            textAlign={i18n.language === "en" ? "right" : "left"}
          >
            <Images.SUPPORT />
          </Box>
        )}
      </Stack>


      </Container>
    </Stack>
  );
};
export default Hero;
