import { Helmet } from "react-helmet";
import { useEffect } from "react";
import Hero from "../components/hero/hero.component";
import Categories from "../components/categories/categories.component";
import FeaturedAds from "../components/featured-ads/featured-ads.component";
import { useTranslation } from "react-i18next";
import { CategoryProvider } from "../context/CategoryContext";
import { GET_CATEGORIES } from "../api/queries/categories.queries";
import { useQuery } from "@apollo/client";

const Home = () => {
  const { t,i18n } = useTranslation("common");
  const {
    loading,
    error,
    data,
    refetch,
  } = useQuery(GET_CATEGORIES, {
    variables: {
      showOnScreen: 1,
    },
  });

  useEffect(() => {
    if (error) {
      console.log("error: ", error);
    }
  }, [error]);
  useEffect(() => {
    console.log("loading");
  }, [loading]);
  useEffect(() => {
    refetch();
  }, [i18n?.language]);
  const categories = data?.categories || [];
  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <CategoryProvider categories={categories}>
      <Categories />
      <Hero />
      <FeaturedAds />
      </CategoryProvider>
    </>
  );
};
export default Home;
