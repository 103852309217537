import { createSlice } from "@reduxjs/toolkit";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    selectedCountry: localStorage.getItem("selectedCountry") || null,
    selectedCountryId: localStorage.getItem("country_id") || null,
    selectedState: localStorage.getItem("selectedState") || null,
    selectedStateId: localStorage.getItem("state_id") || null,
  },
  reducers: {
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload.name;
      state.selectedCountryId = action.payload.id;
      localStorage.setItem("selectedCountry", action.payload.name);
      localStorage.setItem("country_id", action.payload.id);
    },
    setSelectedState: (state, action) => {
      state.selectedState = action.payload.name;
      state.selectedStateId = action.payload.id;
      localStorage.setItem("selectedState", action.payload.name);
      localStorage.setItem("state_id", action.payload.id);
    },
    clearLocation: (state) => {
      state.selectedCountry = null;
      state.selectedCountryId = null;
      state.selectedState = null;
      state.selectedStateId = null;
      localStorage.removeItem("selectedCountry");
      localStorage.removeItem("country_id");
      localStorage.removeItem("selectedState");
      localStorage.removeItem("state_id");
    },
  },
});

export const { setSelectedCountry, setSelectedState, clearLocation } =
  locationSlice.actions;
export default locationSlice.reducer;
