import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import SectionTitle from "../../../components/section-title/section-title.component";
import { Helmet } from "react-helmet";
import axios from "axios";
import Toaster from "../../../components/toaster/toaster.component";
import Icons from "../../../assets/icons";
import { useDispatch } from "react-redux";
import * as ROUTES from "../../../constants/routes";
import { Button } from "@mui/material";
import { setSelectedCountry, setSelectedState } from "../../../store/slices/locationSlice";
import { BounceLoader } from "react-spinners";
const apiURL = process.env.REACT_APP_REST_API_URL;

const CountrySelection = () => {
  const { i18n, t } = useTranslation("common");
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const selectedCountry = useSelector(
    (state) => state.location.selectedCountry
  );
  const selectedState = useSelector((state) => state.location.selectedState);
  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${apiURL}/countries`, {
          headers: {
            "X-App-Language": i18n?.language,
          },
        });
        setCountries(response.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        setLoadingCountries(false);
      }
    };
    fetchCountries();
  }, []);

  const handleChange = async (event) => {
    const selectedCountryName = event.target.value;
    const selectedCountryObj = countries.find(
      (country) => country.name === selectedCountryName
    );
  
    if (selectedCountryObj) {
      // Dispatch country name and ID
      dispatch(
        setSelectedCountry({
          name: selectedCountryObj.name,
          id: selectedCountryObj.id,
        })
      );
  
      setLoadingStates(true);
      try {
        const response = await axios.get(
          `${apiURL}/states-by-country?country_id=${selectedCountryObj.id}`,
          {
            headers: {
              "X-App-Language": i18n?.language,
            },
          }
        );
        setStates(response.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoadingStates(false);
      }
    } else {
      setStates([]);
    }
  };
  
 
    const handleStateSelection = (state) => {
      if (state.name === "ALL") {
        dispatch(setSelectedState(null));  
        localStorage.removeItem("selectedState");
        localStorage.removeItem("state_id");
        navigate(ROUTES.POST_AD)
      } else {
        dispatch(
          setSelectedState({
            name: state.name,
            id: state.id,
          })
        );
        localStorage.setItem("selectedState", state.name);
        localStorage.setItem("state_id", state.id);
        navigate(ROUTES.POST_AD)
      }
  
    };

    useEffect(() => {
      const storedState = localStorage.getItem("selectedState");
      const storedCountry = localStorage.getItem("selectedCountry");
      const storedStateId = localStorage.getItem("state_id");
      const storedCountryId = localStorage.getItem("country_id");
      if (storedCountry && storedCountryId) {
        dispatch(setSelectedCountry({ name: storedCountry, id: storedCountryId }));
        const fetchStatesForStoredCountry = async () => {
          setLoadingStates(true);
          try {
            const response = await axios.get(
              `${apiURL}/states-by-country?country_id=${storedCountryId}`,
              { headers: { "X-App-language": i18n.language } }
            );
            setStates(response.data);
          } catch (error) {
            console.error("Error fetching states:", error);
          } finally {
            setLoadingStates(false);
          }
        };
        fetchStatesForStoredCountry();
      }
      if (storedState && storedStateId) {
        dispatch(setSelectedState({ name: storedState, id: storedStateId }));
      }
    }, [dispatch, apiURL, i18n.language]);

  return (
    <>
      <Helmet>
        <title>{t("post-ad-title")}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1,user-scalable=no"
        />
      </Helmet>

      <Box
        style={{
          gap: 20,
          display: "grid",
          margin: "35px 0",
          padding: 16,
        }}
      >
        <Box
          margin="auto"
          gap={"5px !important"}
          display="grid"
          sx={{ placeItems: "center" }}
        >
          <SectionTitle variant={"h2"} title={t("post-ad.place-ad")} />
          <Typography color={theme.palette.primary.main}>
            {t("post-ad.country-description")}
          </Typography>
        </Box>

        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item xs={3}>
            <Box style={{ width: "100%" }}>
            <div className="cities-container">
              <Box style={{ width: "100%" }}>
                <FormControl variant="outlined" size="small" fullWidth>
           
                  <Select
                    native
                    labelId="country-select"
                    id="select"
                    value={selectedCountry}
                    onChange={handleChange}
                    sx={{
                      color: "#000 !important",
                      "&:focus": {
                        border: "none",
                      },
                    }}
                  >
                    {countries
                      .filter((option) => option.active_for_listing === 1)
                      .map((option) => (
                        <option
                          style={{ color: "#000" }}
                          key={option.id}
                          value={option.name}
                        >
                          {option.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <div className="city-btns">
                {loadingStates ? (
                  <BounceLoader color="#FE820E" size={30} />
                ) : (
                  states.map((state) => (
                    <Button
                      key={state.id}
                      className="state-btn"
                      onClick={() => handleStateSelection(state)}
                      sx={{
                        border: "1px solid #EFEFEF",
                      }}
                    >
                      {state.name}
                    </Button>
                  ))
                )}
              </div>
            </div>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Toaster
        open={openSuccess}
        title={`${t("country.toast-title")}!`}
        description={t("country.toast-description")}
        handleClose={handleCloseSuccess}
        icon={<Icons.CLOSE style={{ margin: "15px 0" }} />}
      />
    </>
  );
};

export default CountrySelection;
