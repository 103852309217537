import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
} from "@apollo/client";
import { useMutation as useReactQueryMutation } from "react-query";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
  Alert,
  Collapse,
  IconButton,
  useTheme,
} from "@mui/material";
import BasicDatePicker from "../../../components/date-picker/date-picker.component";
import FormInput from "../../../components/form-input/form-input.component";
import CustomTelInput from "../../../components/tel-input/tel-Input.component";
import CustomDropzoneDialog from "../../../components/custom-dropzone-dialog/custom-dropzone-dialog.component";
import Toaster from "../../../components/toaster/toaster.component";
import MultipleSelect from "../../../components/multiple-select/multiple-select.component";
import Places from "../../../components/geo-map/places.component";
import { dateFormmaterNoTime } from "../../../helpers/global";
import { useMediaQuery } from "@mui/material";
import {
  FETCH_AMENITIES,
  STORE_PROPERTY_MUTATION,
  UPLOAD_IMAGES,
  DELETE_IMAGES,
  FETCH_FORM,
} from "../../../api/queries/form.queries";
import { makeStyles } from "@mui/styles";
import Icons from "../../../assets/icons";
import Text from "../../../components/dynamic-form/Text";
import {
  GET_MOTOR_EXTRAS,
  STORE_MOTOR_MUTATION,
} from "../../../api/queries/motors.queries";
import {
  GET_MOTORS_MODELS,
  GET_MOTORS_MAKE,
} from "../../../api/queries/motors.queries";
import {
  ConnectingAirportsOutlined,
  ContentPasteOffOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { formControlClasses } from "@mui/base";

const apiURL = process.env.REACT_APP_REST_API_URL;

const useStyles = makeStyles((theme) => ({
  selectValue: {
    color: theme.palette.primary.main,
  },
  placeholder: {
    color: theme.palette.primary.main,
  },
}));
const DynamicForm = ({
  fetchForm,
  activeStep,
  setActiveStep,
  categoryId,
  mainCategoryId,
}) => {



  const { id } = useParams();
  const selectedStateId = String(
    useSelector((state) => state.location.selectedStateId)
  );
  const form_id = localStorage.getItem("form_category");
  const FormId = parseInt(form_id);
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles(theme);
  const [savedData, setSavedData] = useState({});
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedMotorExtras, setSelectedMotorExtras] = useState([]);
  const [motorMakeOptions, setMotorMakeOptions] = useState([]);
  const [motorModelOptions, setMotorModelOptions] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [uploadedFormattedFiles, setUploadedFormattedFiles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);





  const handleSelectAmenities = (options) => {
    setSelectedAmenities(options);
  };



  const handleSelectedMotorExtras = (options) => {
    setSelectedMotorExtras(options);
  };

  useEffect(() => {
    formik.handleChange(selectedAmenities.join(","));
    formik.setFieldValue("amenities", selectedAmenities.join(","));
  }, [selectedAmenities]);

  useEffect(() => {
    formik.handleChange(selectedMotorExtras.join(","));
    formik.setFieldValue("motor_extras", selectedMotorExtras.join(","));
  }, [selectedMotorExtras]);

  useEffect(() => {
    if (selectedPlace) {
      const coordinates = selectedPlace.center;
      const placeName = selectedPlace.place_name;

      formik.setFieldValue("location", coordinates.join(","));
      formik.setFieldValue("neighbourhood", placeName);
    }

   
  }, [selectedPlace]);

  useEffect(() => {
    if (selectedPlace) {
      const coordinates = selectedPlace.center;
      const placeName = selectedPlace.place_name;

      if (coordinates && placeName) {
        console.log(
          "Setting formik fields with:",
          coordinates.join(","),
          placeName
        );
        formik.setFieldValue("location", coordinates.join(","));
        formik.setFieldValue("neighbourhood", placeName);
      } else {
        console.error("Invalid selectedPlace structure", selectedPlace);
      }
    }
  }, [selectedPlace]);

  const createSchema = (fields) => {
    if (!fields || !fields.fetchForm) {
      return yup.object();
    }
    let schema = {};
    fields.fetchForm.forEach((step) => {
      if (Array.isArray(step)) {
        step.forEach((field) => {
          const field_name = field.field_name;
          if (field.field_request_type === "BOOLEAN") {
            schema[field_name] = yup
              .boolean()
              .oneOf([true, false], "This field is required");
          } else if (field.field_type === "date") {
            schema[field_name] = yup.date();
          } else {
            schema[field_name] = yup.string();
          }
          if (
            !field.field_validation ||
            field.field_validation.toLowerCase() !== "optional"
          ) {
            schema[field_name] = schema[field_name].required(
              "This field is required"
            );
          }
        });
      }
    });
    return yup.object().shape(schema);
  };
  const [selectedDate, setSelectedDate] = useState(
    dayjs(dayjs().format("YYYY-MM-DD"))
  );
  useEffect(() => {
    formik.setFieldValue("ready_by", dateFormmaterNoTime(selectedDate));
  }, [selectedDate]);
  useEffect(() => {
    let fileNames = uploadedFormattedFiles
      ?.map((item) => item.file_name)
      .join(",");
    formik.setFieldValue("images", fileNames);
  }, [uploadedFormattedFiles]);

  useEffect(() => {
    let fileNames = uploadedFormattedFiles
      ?.map((item) => item.file_name)
      .join(",");
    formik.setFieldValue("motor_images", fileNames);
  }, [uploadedFormattedFiles]);

  const {
    laoding: isLoadingExreas,
    error: errorLoadingExtras,
    data: extrasList,
  } = useApolloQuery(GET_MOTOR_EXTRAS);

  useEffect(() => {
    if (errorLoadingExtras) {
      console.log("error", errorLoadingExtras);
    }
  }, [errorLoadingExtras]);

  useEffect(() => {
    console.log("loading");
  }, [isLoadingExreas]);

  const {
    loading: isModelLoading,
    error: errorLoadingModel,
    data: modelList,
  } = useApolloQuery(GET_MOTORS_MODELS, {
    variables: {
      makeId: 1,
    },
  });

  useEffect(() => {
    console.log("loading");
  }, [isModelLoading]);

  useEffect(() => {
    console.log("error", errorLoadingModel);
  }, [errorLoadingModel]);

  const {
    loading: isMakeLoading,
    error: errorLoadingMake,
    data: makeList,
  } = useApolloQuery(GET_MOTORS_MAKE);

  useEffect(() => {
    console.log("loading");
  }, [isMakeLoading]);

  useEffect(() => {
    console.log("error", errorLoadingMake);
  }, [errorLoadingMake]);

  useEffect(() => {
    if (modelList && modelList.motor_models) {
      setMotorModelOptions(
        modelList.motor_models.map((model) => ({
          value: model.id,
          text: model.title,
        }))
      );
    }
    if (makeList && makeList.motor_makes && makeList.motor_makes.data) {
      setMotorMakeOptions(
          makeList.motor_makes.data.map((make) => ({
              value: make.id,
              text: make.title,
          }))
      );
  }
  }, [modelList, makeList]);

  const {
    loading: isLoadingAmenities,
    error: errorLoadingAmenities,
    data: amenitiesList,
  } = useApolloQuery(FETCH_AMENITIES, {
    variables: {
      showOnScreen: 1,
    },
  });
  useEffect(() => {
    if (errorLoadingAmenities) {
      console.log("error: ", errorLoadingAmenities);
    }
  }, [errorLoadingAmenities]);
  useEffect(() => {
    console.log("loading");
  }, [isLoadingAmenities]);
  const getInitialValueForField = (field_name, field_extras) => {
    const savedStepData = savedData[activeStep];
    if (savedStepData && savedStepData.values[field_name] !== undefined) {
      return savedStepData.values[field_name];
    }
    const fieldsToBeEmpty = [
      "reference_number",
      "buyer_transfer_fee",
      "seller_transfer_fee",
      "maintenance_fee",
      "amenities",
    ];
    if (fieldsToBeEmpty.includes(field_name)) {
      return "";
    }
    switch (field_name) {
      case "images":
        return "";
      case "ready_by":
        return new Date();
      case "occupancy_status":
      case "furnished":
        return field_extras?.options?.[0]?.value || true;
      case "next":
        return true;
      case "submit":
        return false;
      case "posted_by":
        return field_extras?.options?.[0]?.value || "landlord";
      default:
        return "";
    }
  };
  const generateStepDetails = (properties, step) => {
    const initialValues = {};
    const fieldTypeCounter = {};
    properties?.fetchForm?.forEach((row) => {
      row
        ?.map((item) => ({
          ...item,
          field_extras: JSON.parse(item?.field_extras),
        }))
        .forEach((item, index) => {
          initialValues[item.field_name] = getInitialValueForField(
            item.field_name,
            item.field_extras
          );
          const fieldTypeKey = `${item.field_type}_${index}_${step}`;
          if (fieldTypeCounter[fieldTypeKey]) {
            const count = fieldTypeCounter[fieldTypeKey];
            fieldTypeCounter[fieldTypeKey] = count + 1;
          } else {
            fieldTypeCounter[fieldTypeKey] = 1;
          }
        });
    });
    return {
      defaultValues: initialValues,
    };
  };
  const stepDetails = useMemo(
    () => generateStepDetails(fetchForm, activeStep),
    [fetchForm, activeStep]
  );
  const formik = useFormik({
    initialValues: stepDetails.defaultValues,
    validationSchema: createSchema(fetchForm),
    onSubmit: () => console.log("submitted"),
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
  });
  const uploadImagesFunc = useReactQueryMutation(UPLOAD_IMAGES);
  const deleteImageFunc = useReactQueryMutation(DELETE_IMAGES, {
    async onSuccess(response) {
      console.log(response);
    },
    onError(error) {
      console.log(error.response?.data.error);
    },
  });
  const getSavedData = (step) => {
    // Retrieve saved data based on the step from the state
    const savedStepData = savedData[`step${step + 1}`]; // Assuming your state contains keys like "step1", "step2", etc.
    // Return the saved data or an empty object
    return savedStepData ? { [step]: { values: savedStepData.values } } : {};
  };
  const moveToNextStep = async () => {
    if (activeStep !== null) {
      // Manually touch all fields
      Object.keys(formik.values).forEach((fieldName) => {
        formik.setFieldTouched(fieldName, true);
      });
      // Validate the form
      const validationSchema = createSchema(fetchForm);
      try {
        await validationSchema.validate(formik.values, { abortEarly: false });
        // Save the form data for the current step, including fetchForm
        const currentStepData = {
          values: {},
        };
        // Ensure that all fields are included in the currentStepData.values object
        Object.keys(formik.values).forEach((fieldName) => {
          // Check if the value is not null before saving
          const value = formik.values[fieldName];
          currentStepData.values[fieldName] = value !== null ? value : "";
        });
        // Save the form data for the current step
        setSavedData((prevSavedData) => ({
          ...prevSavedData,
          [activeStep]: currentStepData,
        }));
        setActiveStep((prevStep) => prevStep + 1);
      } catch (validationErrors) {
        const errors = {};
        validationErrors.inner.forEach((error) => {
          errors[error.path] = error.message;
        });
        formik.setErrors(errors);
      }
    }
  };
  const onClickBack = () => {
    setActiveStep((prevStep) => {
      const updatedStep = prevStep - 1;
      // Get the saved data for the previous step
      const savedDataForStep = getSavedData(updatedStep);
      // Set form values for each field using formik.setFieldValue
      Object.keys(savedDataForStep[updatedStep]?.values || {}).forEach(
        (fieldName) => {
          formik.setFieldValue(
            fieldName,
            savedDataForStep[updatedStep]?.values[fieldName]
          );
        }
      );
      setSavedData((prevSavedData) => {
        return {
          ...prevSavedData,
          [prevStep]: {
            values: { ...formik.values },
          },
        };
      });
      return updatedStep;
    });
  };
  const [storePropertyMutation] = useApolloMutation(STORE_PROPERTY_MUTATION);
  const [storeMotorMutation] = useApolloMutation(STORE_MOTOR_MUTATION);

  const handleSubmit = async () => {
    Object.keys(formik.values).forEach((fieldName) => {
      formik.setFieldTouched(fieldName, true);
    });
    // Validate the form
    const validationSchema = createSchema(fetchForm);
    const combinedValues = {
      category_id: categoryId || id,
      emirates : selectedStateId
      // country_id: countryId,
    };

    

    try {
      await validationSchema.validate(formik.values, { abortEarly: false });
      // Save the form data for the current step, including fetchForm
      const currentStepData = {
        values: {},
      };
      // Ensure that all fields are included in the currentStepData.values object
      Object.keys(formik.values).forEach((fieldName) => {
        // Check if the value is not null before saving
        const value = formik.values[fieldName];
        currentStepData.values[fieldName] = value !== null ? value : "";
      });
      Object.keys(savedData).forEach((key) => {
        if (savedData[key] && savedData[key].values) {
          Object.assign(combinedValues, savedData[key].values);
        }
      });
      Object.assign(combinedValues, currentStepData.values);
      combinedValues["next"] = false;
      combinedValues["submit"] = true;
      if (mainCategoryId === 3) {
        delete combinedValues["motor_extras"];
        delete combinedValues["motor_images"];
      } else if (mainCategoryId === 7) {
        delete combinedValues["images"];
        delete combinedValues["extras"];
      }
    } catch (validationErrors) {
      const errors = {};

      console.log(errors, "errors");
      validationErrors.inner.forEach((error) => {
        errors[error.path] = error.message;
      });

      console.log(errors);
      formik.setErrors(errors);
      return;
    }

    try {
      formik.setValues(combinedValues);
      formik.submitForm();
      formik.resetForm();
      Object.keys(formik.values).forEach((fieldName) => {
        formik.setFieldTouched(fieldName, false);
      });

      let Mutation =
        mainCategoryId === 3 ? storePropertyMutation : storeMotorMutation;
      const response = await Mutation({
        variables: { args: combinedValues },
      });

      console.log(response)

      setOpenSuccess(true);
      setSavedData({});
      setSelectedAmenities([]);
      setSelectedMotorExtras([]);
      setUploadedFormattedFiles([]);
      setSelectedDate(dayjs(dayjs().format("YYYY-MM-DD")));
      setSelectedPlace(null);
      setActiveStep(0);
      setTimeout(() => {
        setOpenSuccess(false);
      }, 2000);
    } catch (error) {

      console.log(error, 'error')
      const { message } = error;

      console.log(message, 'mesage')

      setErrorMessage(message);
      setOpenAlert(true);
    }
  };

  // const handleSubmit = async () => {
  //   Object.keys(formik.values).forEach((fieldName) => {
  //     formik.setFieldTouched(fieldName, true);
  //   });

  //   // Validate the form
  //   const validationSchema = createSchema(fetchForm);
  //   const combinedValues = {
  //     category_id: categoryId,
  //   };

  //   console.log("Initial combinedValues:", combinedValues);

  //   try {
  //     await validationSchema.validate(formik.values, { abortEarly: false });

  //     const currentStepData = {
  //       values: {},
  //     };

  //     // Ensure that all fields are included in the currentStepData.values object
  //     Object.keys(formik.values).forEach((fieldName) => {
  //       const value = formik.values[fieldName];
  //       currentStepData.values[fieldName] = value !== null ? value : "";
  //     });

  //     Object.keys(savedData).forEach((key) => {
  //       if (savedData[key] && savedData[key].values) {
  //         Object.assign(combinedValues, savedData[key].values);
  //       }
  //     });

  //     Object.assign(combinedValues, currentStepData.values);
  //     combinedValues["next"] = false;
  //     combinedValues["submit"] = true;

  //     if (mainCategoryId === 3) {
  //       delete combinedValues["motor_extras"];
  //       delete combinedValues["motor_images"];
  //     } else if (mainCategoryId === 7) {
  //       delete combinedValues["images"];
  //       delete combinedValues["extras"];
  //     }

  //     console.log("Combined values after assignment:", combinedValues);
  //   } catch (validationErrors) {
  //     const errors = {};

  //     validationErrors.inner.forEach((error) => {
  //       errors[error.path] = error.message;
  //     });

  //     console.error("Validation errors:", errors);
  //     formik.setErrors(errors);
  //     return;
  //   }

  //   try {
  //     formik.setValues(combinedValues);

  //     console.log("Formik values before submit:", formik.values);

  //     await formik.submitForm();
  //     formik.resetForm();
  //     Object.keys(formik.values).forEach((fieldName) => {
  //       formik.setFieldTouched(fieldName, false);
  //     });

  //     const Mutation =
  //       mainCategoryId === 3 ? storePropertyMutation : storeMotorMutation;
  //     const response = await Mutation({
  //       variables: { args: combinedValues },
  //     });

  //     console.log("Mutation response:", response);

  //     if (response.errors) {
  //       console.error("Mutation response errors:", response.errors);
  //       setErrorMessage("Something went wrong");
  //       setOpenAlert(true);
  //     } else {
  //       setOpenSuccess(true);
  //       setSavedData({});
  //       setSelectedAmenities([]);
  //       setSelectedMotorExtras([]);
  //       setUploadedFormattedFiles([]);
  //       setSelectedDate(dayjs(dayjs().format("YYYY-MM-DD")));
  //       setSelectedPlace(null);
  //       setActiveStep(0);
  //       setTimeout(() => {
  //         setOpenSuccess(false);
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     console.error("Catch error:", error);
  //     setErrorMessage(error.message || "An unexpected error occurred");
  //     setOpenAlert(true);
  //   }
  // };

  const onDropzoneFilesChange = async (newFiles, field_name) => {
    if (newFiles && newFiles.length) {
      const formattedFiles = newFiles.map((file) => file);
      formik.setFieldValue(field_name, formattedFiles);
      // Use Promise.all to wait for all mutations to complete
      const mutationPromises = formattedFiles.map((file) => {
        const data = new FormData();
        data.append("type", "PROPERTY_IMAGES_URL");
        data.append("file", file);
        return uploadImagesFunc
          .mutateAsync(data)
          .then((response) => {
            const file_name = response["file_name"];
            const key = uuidv4();
            return { key, file, file_name };
          })
          .catch((error) => {
            console.log(error.response?.data.error);
            return null;
          });
      });
      const updatedFiles = await Promise.all(mutationPromises);
      // Remove null values (in case of errors)
      const filteredUpdatedFiles = updatedFiles.filter(Boolean);
      // Update the state once with all files
      setUploadedFormattedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...filteredUpdatedFiles,
      ]);
    }
  };
  const handleDeleteImage = (keyToDelete) => {
    let deletedFileName = null;
    setUploadedFormattedFiles((prevUploadedFiles) => {
      const updatedFiles = prevUploadedFiles.filter((file) => {
        if (file.key === keyToDelete) {
          deletedFileName = file.file_name;
          return false;
        }
        return true;
      });
      return updatedFiles;
    });
    const data = new FormData();
    data.append("type", "PROPERTY_IMAGES_URL");
    data.append("key", deletedFileName);
    deleteImageFunc.mutate(data);
  };
  const generateFormFields = (fields, formik) => {
    if (
      !fields ||
      fields.fetchForm == null ||
      !Array.isArray(fields.fetchForm)
    ) {
      return null;
    }
    // Create a map to store fields grouped by order
    const groupedFields = new Map();
    // Group fields by order
    fields.fetchForm.forEach((step) => {
      step.forEach((field) => {
        const { field_order } = field;
        if (!groupedFields.has(field_order)) {
          groupedFields.set(field_order, []);
        }
        groupedFields.get(field_order).push(field);
      });
    });
    // Sort the field orders
    const sortedOrder = [...groupedFields.keys()].sort((a, b) => a - b);
    return sortedOrder.map((order) => (
      <div
        key={order}
        style={{
          display: groupedFields.get(order).length > 1 ? "flex" : "inline",
          justifyContent:
            groupedFields.get(order).length > 1 ? "space-between" : "unset",
          gap: 20,
          flexDirection: isMobile? 'column' : 'row'
        }}
      >
        {groupedFields.get(order)?.map((field, fieldIndex) => {
          const {
            field_id,
            field_name,
            field_type,
            field_extras,
            field_size,
            field_order,
          } = field;
          let extras;
          try {
            extras = field_extras ? JSON.parse(field_extras) : {};
          } catch (error) {
            console.error("Error parsing field_extras:", error);
          }
          const initialCheckedState = formik?.values
            ? formik.values[field_name] ?? false
            : false;
          if (!field || !field.field_name) {
            return null;
          }

          switch (field_type) {
            case "text":
            case "price":
            case "float":
            case "textview":
              return (
                <Box
                  key={fieldIndex}
                  style={{
                    order: field_order,
                    width: "100%",
                  }}
                >
                  <FormInput
                    {...formik.getFieldProps(field_name)}
                    name={field_name}
                    fullWidth
                    placeholder={extras?.title}
                    type={field_type === "price" ? "number" : "text"}
                    sx={{
                      position: extras?.position ? "relative" : "unset",
                      textAlign: extras?.alignment ? "left" : "unset",
                      borderRadius: "4px",
                      margin: 0,
                      textTransform: "capitalize",
                      minWidth: "fit-content",
                      width: "100%",
                    }}
                    label={
                      field_type === "textview"
                        ? "Description"
                        : extras
                        ? extras?.title
                        : ""
                    }
                    currency={
                      field_type === "price" ? (
                        "AED"
                      ) : field_name === "neighbourhood" ? (
                        <Icons.GPS_FIXED />
                      ) : (
                        ""
                      )
                    }
                    multiline={field_type === "textview"}
                    error={
                      formik.touched[field_name] &&
                      Boolean(formik.errors[field_name])
                    }
                    helperText={
                      formik.touched[field_name] && formik.errors[field_name]
                    }
                    value={
                      formik.values && formik.values[field_name]
                        ? formik.values[field_name]
                        : ""
                    }
                  />
                </Box>
              );

            case "phone":
              return (
                <Box
                  key={fieldIndex}
                  style={{
                    order: field_order,
                    width: groupedFields.get(order).length > 1 ? "46%" : "100%",
                  }}
                >
                  <CustomTelInput
                    key={field_name}
                    name={field_name}
                    label={extras.title}
                    {...formik.getFieldProps(field_name)}
                    style={{
                      position: extras.position ? "relative" : "unset",
                      textAlign: extras.alignment ? "left" : "unset",
                      width: field_size ? `${field_size}%` : "unset",
                      borderRadius: 4,
                      padding: 0,
                      margin: 0,
                    }}
                    error={
                      formik.touched[field_name] &&
                      Boolean(formik.errors[field_name])
                    }
                    helperText={
                      formik.touched[field_name] && formik.errors[field_name]
                    }
                    value={formik.values[field_name]}
                    onChange={(value) =>
                      formik.setFieldValue(field_name, value.replace(/\+/g, "").replace(/\s/g, ""))
                    }
                  />
                </Box>
              );
            case "file":
              return (
                <Box
                  style={{
                    order: field_order,
                    width: groupedFields.get(order).length > 1 ? "46%" : "100%",
                  }}
                  key={fieldIndex}
                >
                  <CustomDropzoneDialog
                    title={extras.title}
                    onFilesChange={(newFiles) =>
                      onDropzoneFilesChange(newFiles, field_name)
                    }
                    filesList={uploadedFormattedFiles}
                    modalIsOpen={openDialog}
                    setModalIsOpen={setOpenDialog}
                    onDeleteFile={handleDeleteImage}
                  />
                </Box>
              );
            case "switch":
              return (
                <Box
                  key={fieldIndex}
                  style={{
                    order: field_order,
                    width: groupedFields.get(order).length > 1 ? "46%" : "100%",
                  }}
                >
                  <FormControlLabel
                    key={field_name}
                    control={
                      <>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems={"center"}
                          width="88%"
                        >
                          <Typography color={theme.palette.primary.main}>
                            {field_name === "furnished"
                              ? "Is this Furnished?"
                              : ""}
                          </Typography>
                          <Typography color={theme.palette.primary.main}>
                            No Yes
                          </Typography>
                        </Box>
                        <Switch
                          {...formik.getFieldProps(field_name)}
                          title={extras?.title}
                          name={field_name}
                          checked={
                            Boolean(formik.values[field_name]) ??
                            Boolean(initialCheckedState)
                          }
                          onChange={(event) => {
                            formik.setFieldValue(
                              field_name,
                              event.target.checked
                            );
                          }}
                          size="medium"
                          sx={{
                            "&.MuiSwitch-root.MuiSwitch-sizeMedium": {
                              padding: "8px !important",
                            },
                            "& .MuiSwitch-switchBase": {
                              color: theme.palette.primary.main,
                              "&.Mui-checked": {
                                transform: "translateX(20px)",
                                color: theme.palette.primary.main,
                                "& + .MuiSwitch-track": {
                                  backgroundColor: "#F1F1F1",
                                  opacity: 1,
                                },
                              }, 
                            },
                            "& .MuiSwitch-track": {
                              borderRadius: "16px",
                              opacity: "unset",
                              backgroundColor: "#F1F1F1",
                            },
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </>
                    }
                    label={""}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: 0,
                    }}
                  />
                </Box>
              );
            case "dropdown":
              const fieldTitle = extras?.title || "";
              let fieldOptions = extras?.options || [];
              const helperText = extras?.helperText || "";

              if (field_name === "model_id" && motorModelOptions.length > 0) {
                fieldOptions = motorModelOptions;
              } else if (
                field_name === "make_id" &&
                motorMakeOptions.length > 0
              ) {
                fieldOptions = motorMakeOptions;
              }
              return (
                <Box
                  key={fieldIndex}
                  style={{ order: field_order, width: "100%" }}
                >
                  <FormControl
                    key={field_name}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={Boolean(
                      formik.touched[field_name] && formik.errors[field_name]
                    )}
                  >
                    <InputLabel htmlFor={field_name}>{fieldTitle}</InputLabel>
                    <Select
                      native
                      label={fieldTitle}
                      inputProps={{
                        name: field_name,
                        id: field_id,
                      }}
                      value={formik.values[field_name] || ""}
                      onChange={(event) => {
                        formik.handleChange(event);
                        formik.setFieldValue(field_name, event.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      className={classes.selectValue}
                      {...formik.getFieldProps(field_name)}
                    >
                      <option value="" disabled></option>
                      {fieldOptions?.map((option, index) => (
                        <option
                          style={{ color: theme.palette.primary.main }}
                          key={index}
                          value={option.value}
                        >
                          {option.text}
                        </option>
                      ))}
                    </Select>
                    {helperText && (
                      <FormHelperText>{helperText}</FormHelperText>
                    )}
                  </FormControl>
                  {formik.touched[field_name] && formik.errors[field_name] && (
                    <div style={{ color: theme.palette.error.main }}>
                      {formik.errors[field_name]}
                    </div>
                  )}
                </Box>
              );
            case "date":
              return (
                <Box
                  key={fieldIndex}
                  style={{ order: field_order, width: "100%" }}
                >
                  <BasicDatePicker
                    {...formik.getFieldProps(field_name)}
                    label={extras?.title}
                    value={formik.values[field_name]}
                    setValue={setSelectedDate}
                    handleChange={(event) => {
                      setSelectedDate(event);
                    }}
                  />
                </Box>
              );
            case "selection_popup":
              return (
                <Box
                  key={fieldIndex}
                  style={{ order: field_order, width: "100%" }}
                >
                  <MultipleSelect
                    name={field_name}
                    label={extras.title}
                    data={
                      mainCategoryId === 3
                        ? amenitiesList?.amenities
                        : extrasList?.motor_extras
                    }
                    option={
                      mainCategoryId === 3
                        ? selectedAmenities
                        : selectedMotorExtras
                    }
                    setOption={(options) => {
                      handleSelectAmenities(options);
                      setSelectedMotorExtras(options);
                    }}
                    {...formik.getFieldProps(field_name)}
                    itemStyle={{
                      padding: "5px 10px",
                      justifyContent: "center",
                      borderRadius: "8px",
                      border: `1px solid ${theme.palette.primary.main}`,
                      background: theme.palette.primary.main,
                      color: theme.palette.secondary.main,
                      width: "max-content",
                      margin: "5px 10px",
                      lineHeight: "unset",
                      letterSpacing: "unset",
                      float: "left",
                    }}

                    sx={{
                    
                      "& .MuiSelect-select div": {
                       
                  
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "8px",
                      },
                      
                    }}
                  />
                </Box>
              );
            case "radio":
              return (
                <Box
                  key={fieldIndex}
                  style={{
                    order: field_order,
                    width: groupedFields.get(order).length > 1 ? "46%" : "100%",
                  }}
                >
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{ color: theme.palette.primary.main }}
                    >
                      {extras.title}
                    </FormLabel>
                    <RadioGroup
                      name={field_name}
                      value={
                        formik.values && formik.values[field_name] !== undefined
                          ? formik.values[field_name]
                          : JSON.parse(field_extras)?.options?.[0]?.value
                      }
                      onChange={(event) => {
                        formik.setFieldValue(field_name, event.target.value);
                      }}
                      row
                    >
                      {extras.options?.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.text}
                          style={{ color: theme.palette.primary.main }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Box>
              );
            case "button":
              return (
                <Box
                  key={fieldIndex}
                  style={{
                    order: field_order,
                    width: groupedFields.get(order).length > 1 ? "46%" : "100%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    gap: "20px !important",
                    display: "grid",
                  }}
                >
                  <Button
                    key={field_id}
                    variant="contained"
                    color="primary"
                    onClick={
                      extras?.button_action === "next_step"
                        ? moveToNextStep
                        : extras.button_action === "submit"
                        ? () => handleSubmit()
                        : () => console.log("reset")
                    }
                    style={{
                      position: extras?.position ? "relative" : "unset",
                      textAlign: extras?.alignment ? "left" : "unset",
                      padding: 15,
                      borderRadius: 80,
                      background: theme.palette.primary.orange,
                      color: theme.palette.secondary.main,
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                    sx={{ width: `${field_size}% !important` }}
                    type={
                      extras?.button_action === "next_step"
                        ? "button"
                        : extras.button_action === "submit"
                        ? "submit"
                        : "reset"
                    }
                  >
                    {extras.title}
                  </Button>
                  {activeStep > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onClickBack}
                      style={{
                        background: "transparent",
                        boxShadow: "unset",
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                      sx={{ color: theme.palette.primary.main }}
                    >
                      Back
                    </Button>
                  )}
                </Box>
              );
            case "map":
              return (
                <Box key={fieldIndex}>
                  <Places
                    {...formik.getFieldProps(field_name)}
                    formik={formik}
                    locationName={field_name}
                    locationType={field_type === "price" ? "number" : "text"}
                    locationStyles={{
                      position: extras?.position ? "relative" : "unset",
                      textAlign: extras?.alignment ? "left" : "unset",
                      width: field_size ? `${field_size}%` : "unset",
                      borderRadius: "4px",
                      margin: 0,
                      textTransform: "capitalize",
                      minWidth: "fit-content",
                    }}
                    locationLabel={extras ? extras?.title : ""}
                    locationErr={
                      formik.touched[field_name] &&
                      Boolean(formik.errors[field_name])
                    }
                    locationHelperText={
                      formik.touched[field_name] && formik.errors[field_name]
                    }
                    locationVal={
                      formik.values && formik.values[field_name]
                        ? formik.values[field_name]
                        : ""
                    }
                    locationIcon={<Icons.GPS_FIXED />}
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                  />
                </Box>
              );
            default:
              return null;
          }
        })}
      </div>
    ));
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  return (
    <>
      {errorMessage && (
        <Collapse in={openAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <Icons.CLOSE />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          spacing={2}
          display={"grid"}
          sx={{ gap: "12px !important" }}
          padding="0 15px"
          marginLeft="unset"
          width="unset"
        >
          {generateFormFields(fetchForm, formik)}
        </Grid>
      </form>
      <Toaster
        open={openSuccess}
        title={"congratulations"}
        description={"success"}
        handleClose={handleCloseSuccess}
        icon={<Icons.SUCCESS style={{ margin: "15px 0" }} />}
      />
    </>
  );
};
export default DynamicForm;
