import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import FormBox from "../../../components/form-box/form-box.component";
import FormInput from "../../../components/form-input/form-input.component";
import CustomTelInput from "../../../components/tel-input/tel-Input.component";
import Toaster from "../../../components/toaster/toaster.component";
import * as yup from "yup";
import "yup-phone";
import * as ROUTES from "../../../constants/routes";
import Icons from "../../../assets/icons";
import Loader from "../../../components/loader/Loader";
import { useFormik } from "formik";
const PhoneNumberRegister = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const EmailRegisterSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t("authentication.register.first_name_required")),
    lastName: yup
      .string()
      .required(t("authentication.register.last_name_required")),
    phoneNumber: yup
      .string()
      .nullable()
      .test(
        "is-valid-phone",
        t("authentication.login.invalid_no_phone"),
        (value) => {
          if (value === null || value === "") {
            return true;
          }
          try {
            const phoneUtil = 
              require("google-libphonenumber").PhoneNumberUtil.getInstance();
            const number = phoneUtil.parseAndKeepRawInput(value, "");
            return phoneUtil.isValidNumber(number);
          } catch (error) {
            return false;
          }
        }
      )
      .required(t("authentication.login.phone_no_required")),
    password: yup
      .string()
      .required(t("authentication.login.password_required")),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      phoneNumber: "",
    },
    validationSchema: EmailRegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await onSubmitPhoneNumberRegister(values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
  });
  const onSubmitPhoneNumberRegister = async (event) => {
    event.preventDefault();
    try {
      const { firstName, lastName, password } = formik.values;
      const phoneNumber = formik.values.phoneNumber.replace(/\s/g, "");
      formik.setTouched({
        firstName: true,
        lastName: true,
        password: true,
        phoneNumber: true,
      });

      let type = "phone";
      let registerId = phoneNumber;
      EmailRegisterSchema.validateSync(
        { firstName, lastName, password, phoneNumber },
        { abortEarly: false }
      );
      setLoading(true);
      setTimeout(() => {
        
        setOpenSuccess(false);
        navigate(ROUTES.OTP_CODE, {
          state: {
            data: {
              phoneNumber: phoneNumber,
              firstName: firstName,
              lastName: lastName,
              password: password,
              type: type,
              registerId: registerId,
              key: "reg-by-phone",
            },
          },
        });
      }, 2000);
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((validationError) => {
          validationErrors[validationError.path] = validationError.message;
        });
        formik.setErrors(validationErrors);
      } else {
        const { message } = error;
        setErrorMessage(message);
        setOpenAlert(true);
      }
    }
  };
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  return (
    <>
      {errorMessage && (
        <Collapse in={openAlert}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <Icons.CLOSE />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>
      )}
      <FormBox
        onSubmit={(event) => onSubmitPhoneNumberRegister(event)}
        sx={{ display: "grid" }}
        submitValue={t("authentication.register.sign_up")}
        submitStyle={{
          borderRadius: "8px",
          border: `1px solid ${theme.palette.primary.main}`,
          background: theme.palette.primary.orange,
          color: theme.palette.secondary.main,
          textTransform: "capitalize",
          margin: "25px 0",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("firstName")}
                name="firstName"
                required
                fullWidth
                label={t("authentication.register.first_name")}
                type="text"
                error={
                  formik.touched["firstName"] &&
                  Boolean(formik.errors["firstName"])
                }
                helperText={
                  formik.touched["firstName"] && formik.errors["firstName"]
                }
                value={
                  formik.values && formik.values["firstName"]
                    ? formik.values["firstName"]
                    : ""
                }
                icon={<Icons.USER_DATA style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("lastName")}
                name="lastName"
                required
                fullWidth
                label={t("authentication.register.last_name")}
                type="text"
                error={
                  formik.touched["lastName"] &&
                  Boolean(formik.errors["lastName"])
                }
                helperText={
                  formik.touched["lastName"] && formik.errors["lastName"]
                }
                value={
                  formik.values && formik.values["lastName"]
                    ? formik.values["lastName"]
                    : ""
                }
                icon={<Icons.USER_DATA style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <CustomTelInput
              {...formik.getFieldProps("phoneNumber")}
              name={"phoneNumber"}
              label={t("authentication.login.social.phone_number")}
              error={
                formik.touched["phoneNumber"] &&
                Boolean(formik.errors["phoneNumber"])
              }
              helperText={
                formik.touched["phoneNumber"] && formik.errors["phoneNumber"]
              }
              value={formik.values["phoneNumber"]}
              onChange={(value) => formik.setFieldValue("phoneNumber", value)}
            />
          </Grid>
          <Grid item xs={12} md={12} paddingTop={"unset !important"}>
            <Box margin={"10px 0"}>
              <FormInput
                {...formik.getFieldProps("password")}
                name="password"
                required
                fullWidth
                label={t("authentication.login.password")}
                type="password"
                error={
                  formik.touched["password"] &&
                  Boolean(formik.errors["password"])
                }
                helperText={
                  formik.touched["password"] && formik.errors["password"]
                }
                value={
                  formik.values && formik.values["password"]
                    ? formik.values["password"]
                    : ""
                }
                icon={<Icons.LOCK style={{ margin: "0px 5px" }} />}
              />
            </Box>
          </Grid>
        </Grid>
      </FormBox>
      {
        loading && (
          <div>
          <Loader/>
          </div>
        )
      }
      <Toaster
        open={openSuccess}
        description={
          <Box color="#212121" fontSize={18}>
            <Typography component={"span"} marginRight={1} display="inline">
              {t("authentication.register.otp_code")}
            </Typography>
            <Typography component={"span"} style={{ display: "inline" }}>
              {t("authentication.register.otp_sent_msg")}
            </Typography>
          </Box>
        }
        handleClose={handleCloseSuccess}
      />
    </>
  );
};
export default PhoneNumberRegister;
