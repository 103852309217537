import React, { useState} from 'react';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { FaXmark } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa6";


const buttonStyle ={
    background: "#f5f5f5",
    height:"72px",
    width:"72px",
    borderRadius:"50%"
}

const ShareTo = ({ link, onClose }) => {

    const [isCopied, setIsCopied] = useState(false)

    const encodedLink = encodeURIComponent(link);
    const shareText = "Check out this!";

    const handleShare = (platform) => {
        let url = "";
    
        switch (platform) {
          case "facebook":
            url = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
            break;
          case "twitter":
            url = `https://twitter.com/intent/tweet?url=${encodedLink}&text=${encodeURIComponent(shareText)}`;
            break;
          case "whatsapp":
            url = `https://wa.me/?text=${encodeURIComponent(`${shareText} ${link}`)}`;
            break;
          case "telegram":
            url = `https://t.me/share/url?url=${encodedLink}&text=${encodeURIComponent(shareText)}`;
            break;
          case "email":
            url = `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodedLink}`;
            break;
          default:
            break;
        }
    
        if (url) {
          window.open(url, "_blank");
        }
      };


      const handleCopyLink = () => {
        navigator.clipboard.writeText(link);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
          }, 1000);
      };
  return (
    
    <Box sx={{padding:"20px",position:"relative"}}>
    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
        <Typography sx={{fontSize:"15px !important", fontWeight:"700"}}>Share With</Typography>
        <Button onClick={onClose}><FaXmark /></Button>
    </Box>


    <Box sx={{display:"flex",alignItems:"center",gap:"10px", marginTop:"30px"}}>
        <Box>
        <Button style={buttonStyle} onClick={() => handleShare("facebook")}><FaFacebookF size={20}/></Button>
        <Typography sx={{textAlign:"center", fontSize:"12px !important",marginTop:"10px"}}>Facebook</Typography>
        </Box>
        
        <Box><Button style={buttonStyle} onClick={() => handleShare("twitter")}><FaTwitter size={20}/></Button><Typography sx={{textAlign:"center",fontSize:"12px !important",marginTop:"10px"}}>Twitter</Typography></Box>
        <Box><Button style={buttonStyle} onClick={() => handleShare("whatsapp")}><FaWhatsapp size={20}/></Button><Typography sx={{textAlign:"center",fontSize:"12px !important",marginTop:"10px"}}>Whatsapp</Typography></Box>
        <Box><Button style={buttonStyle} onClick={() => handleShare("telegram")}><FaTelegram size={20}/></Button><Typography sx={{textAlign:"center",fontSize:"12px !important",marginTop:"10px"}}>Telegram</Typography></Box>
        <Box><Button style={buttonStyle} onClick={() => handleShare("email")}><FaEnvelope size={20}/></Button><Typography sx={{textAlign:"center",fontSize:"12px !important",marginTop:"10px"}}>Email</Typography></Box>
    </Box>


    <Typography sx={{textAlign:"center", marginTop:"20px", fontSize:"13px !important",fontWeight:"600"}}>Or share with link</Typography>

    <Box sx={{
        display: "flex",
        justifyContent:"space-between",
        gap: "25px",
        background: "rgba(157, 157, 157, 0.1)",
        borderRadius: "16px",
        padding: "14px 17px",
        marginTop: "10px",
    }}>
        <span style={{fontSize:"12px"}}>{link}</span>
        <FaRegCopy onClick={handleCopyLink} style={{cursor:"pointer"}}/>
    </Box>

    {
        isCopied && (
            <Typography  sx={{textAlign:"center", fontSize:"12px !important",marginTop:"5px"}}>Link copied!</Typography>
        )
    }
        
    </Box>
  )
}

export default ShareTo